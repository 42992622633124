import { Popover } from 'antd';
import iconBell from '../../resources/icons/bell.svg';
import Notifications from './Notifications';
import './NotificationCount.scss';
import { useEffect, useState } from 'react';
import { getLastSeen, streamNotifications, updateLastSeen } from 'services/firebase';
import { useSelector } from 'react-redux';
import moment from 'moment';

function NotificationCount() {
  const [visible, setVisible] = useState();
  const [lastSeen, setLastSeen] = useState(0);
  const [count, setCount] = useState(0);
  const setting = useSelector((state) => state.user.setting);

  useEffect(() => {
    const getData = async () => {
      if (setting?.userId) {
        const last_seen = await getLastSeen(setting?.userId);
        setLastSeen(last_seen);
      }
    };
    getData();
  }, [setting?.userId]);

  useEffect(() => {
    const unsubscribe = streamNotifications(
      setting?.userId,
      lastSeen,
      (querySnapshot) => {
        setCount(querySnapshot?.size || 0);
      },
      (error) => console.log(error),
    );
    return unsubscribe;
  }, [setting?.userId, lastSeen]);

  const onVisibleChange = async (value) => {
    if (value) {
      setCount(0);
      if (count > 0) {
        const time = moment().valueOf();
        updateLastSeen(setting?.userId, time);
        setLastSeen(time);
      }
    }
    setVisible(value);
  };

  const onClosePopover = () => setVisible(false);

  return (
    <Popover
      content={<Notifications onClose={onClosePopover} />}
      popupAlign={{ offset: [-50, 0] }}
      trigger="click"
      placement="bottom"
      overlayInnerStyle={{
        width: 315,
        height: 443,
        overflowY: 'auto',
      }}
      overlayClassName="notifications"
      defaultVisible={false}
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      <div className="relative cursor-pointer w-16px h-16px">
        <img src={iconBell} alt="" className="cursor-pointer w-full h-full object-cover" />
        {count > 0 && (
          <div className="absolute h-20px text-12px -top-12px -right-25px text-white bg-FF4D4F rounded-100px flex-center px-8px">
            {count}
          </div>
        )}
      </div>
    </Popover>
  );
}

export default NotificationCount;
