import { LIST_ROLE } from 'app-constants/permission';
import { useEffect, useState } from 'react';

// 1- Admin, 2- Quản lý tập trung, 3- Township, 4- Trưởng ban quản lý, 5- Giám sát dịch vụ,
// 6- Kế toán, 7- Kỹ thuật, 8- Chăm sóc khách hàng

const getRole = (listRoleId) => {
  return Array.isArray(listRoleId) ? listRoleId?.map((item) => LIST_ROLE[item]) : [];
};

const mergeRole = (listRole) => {
  return listRole.reduce((acc, curr) => {
    for (const key in curr) {
      if (Object.hasOwnProperty.call(curr, key)) {
        const element = curr[key];
        const current = acc[key] ?? [];
        acc[key] = [...current, ...element].filter((item, index, arr) => arr.indexOf(item) === index);
      }
    }
    return acc;
  }, {});
};

export const hasRole = (roles = [], action) => roles.includes(action);

function usePermission({ page, listPermissionId }) {
  const [dataRoles, setDataRoles] = useState([]);
  const [allRoles, setAllRoles] = useState({});

  useEffect(() => {
    if (listPermissionId) {
      const rolesObj = getRole(listPermissionId);
      const rolesMerge = mergeRole(rolesObj) ?? {};
      setDataRoles(rolesMerge[page] ?? []);
      setAllRoles(rolesMerge);
    }
  }, [page, listPermissionId]);

  return {
    dataRoles,
    allRoles,
  };
}

export default usePermission;
