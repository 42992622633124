import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FaceRetouchingNaturalOutlinedIcon from '@mui/icons-material/FaceRetouchingNaturalOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { AppRoutes } from './AppRoutes';

export const MENU_TOP = [
  {
    title: 'project_management',
    link: AppRoutes.project,
  },
  {
    title: 'centralized_department_management',
    link: AppRoutes.department,
  },
  {
    title: 'centralized_employee_management',
    link: AppRoutes.listEmployee,
  },
  {
    title: 'configuration',
    link: AppRoutes.config_account,
  },
];

export const MENU_IDS = {
  menuAccount: 'menuAccount',
  menuInfoUser: 'menuInfoUser',
  menuAccounSecure: 'menuAccounSecure',
  menuLanguage: 'menuLanguage',
  menuOtherConfig: 'menuOtherConfig',
  menuSmsGateway: 'menuSmsGateway',
  menuMail: 'menuMail',
  menuVip: 'menuVip',
  menudRealEstate: 'menudRealEstate',
  menuProjectOverview: 'projectOverview',
  menuProjectProperty: 'projectProperty',
  menuProjectResident: 'projectResident',
  menuProjectDepartment: 'projectDepartment',
  menuProjectEmployee: 'projectEmployee',
  menuProjectInformation: 'projectInformation',
  menuProjectStructure: 'projectStructure',
  menuProjectPropertyOverview: 'projectPropertyOverview',
  menuProjectPropertyHistory: 'projectPropertyHistory',
};

export const MENU_LEFT_CONFIG = [
  {
    id: 'menuAccount',
    title: 'account',
    link: null,
    icon: '',
  },
  {
    id: 'menuInfoUser',
    title: 'personal_information',
    link: AppRoutes.config_account,
    icon: <AccountCircleOutlinedIcon />,
  },
  {
    id: 'menuAccounSecure',
    title: 'account_security',
    link: AppRoutes.config_secure,
    icon: <AdminPanelSettingsOutlinedIcon />,
  },
  {
    id: 'menuLanguage',
    title: 'language',
    link: AppRoutes.config_lang,
    icon: <LanguageOutlinedIcon />,
  },
  {
    id: 'menuOtherConfig',
    title: 'other_configurations',
    link: null,
    icon: '',
  },
  {
    id: 'menuSmsGateway',
    title: 'SMS Gateway',
    link: AppRoutes.config_sms,
    icon: <SmsOutlinedIcon />,
  },
  {
    id: 'menuMail',
    title: 'Mail Server',
    link: AppRoutes.config_mail,
    icon: <EmailOutlinedIcon />,
  },
  {
    id: 'menuVip',
    title: 'vip_resident',
    link: AppRoutes.config_resident_vip,
    icon: <FaceRetouchingNaturalOutlinedIcon />,
  },
  {
    id: 'menudRealEstate',
    title: 'property_label',
    link: AppRoutes.config_real_estate,
    icon: <MapsHomeWorkOutlinedIcon />,
  },
];

export const MENU_LEFT_PROJECT_PROPERTY = [
  {
    id: 'projectPropertyOverview',
    title: 'project.menu.overview',
    link: AppRoutes.projectPropertyOverview,
    icon: <DashboardOutlinedIcon />,
  },
  {
    id: 'projectPropertyDebtContro',
    title: 'menu.property.debt_control',
    link: AppRoutes.projectPropertyOverview,
    icon: <QuestionAnswerOutlinedIcon />,
  },
  {
    id: 'projectPropertyComplaint',
    title: 'menu.property.complaint_management',
    link: AppRoutes.projectPropertyOverview,
    icon: <PriceChangeOutlinedIcon />,
  },
  {
    id: 'projectPropertyHistory',
    title: 'menu.property.property_history',
    link: AppRoutes.projectPropertyHistory,
    icon: <MapsHomeWorkOutlinedIcon />,
  },
];

export const MENU_LEFT_CONFIG_PROFILE = [
  {
    id: 'menuAccount',
    title: 'account',
    link: null,
    icon: '',
  },
  {
    id: 'menuInfoUser',
    title: 'personal_information',
    link: AppRoutes.configProfile,
    icon: <AccountCircleOutlinedIcon />,
  },
  {
    id: 'menuAccounSecure',
    title: 'account_security',
    link: AppRoutes.configProfileSecure,
    icon: <AdminPanelSettingsOutlinedIcon />,
  },
  {
    id: 'menuLanguage',
    title: 'language',
    link: AppRoutes.configProfileLang,
    icon: <LanguageOutlinedIcon />,
  },
];
