import { AppRoutes } from 'app-constants/AppRoutes';
import { MENU_IDS } from 'app-constants/menu';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

function useActiveMenu() {
  const history = useHistory();
  const [menuActive, setMenuActive] = useState('');

  useEffect(() => {
    const getMenuActive = () => {
      let activeItem = '';
      const pathLocation = history.location.pathname;
      switch (pathLocation) {
        //-------------CONFIG-------------
        case AppRoutes.config_account:
        case AppRoutes.config_account_edit:
          activeItem = MENU_IDS.menuInfoUser;
          break;
        case AppRoutes.config_secure:
          activeItem = MENU_IDS.menuAccounSecure;
          break;
        case AppRoutes.config_lang:
          activeItem = MENU_IDS.menuLanguage;
          break;
        case AppRoutes.config_sms:
        case AppRoutes.config_sms_setting:
          activeItem = MENU_IDS.menuSmsGateway;
          break;
        case AppRoutes.config_mail:
        case AppRoutes.config_mail_setting:
        case AppRoutes.config_mail_send:
          activeItem = MENU_IDS.menuMail;
          break;
        case AppRoutes.config_resident_vip:
        case AppRoutes.config_resident_vip_edit:
          activeItem = MENU_IDS.menuVip;
          break;
        case AppRoutes.config_real_estate:
          activeItem = MENU_IDS.menudRealEstate;
          break;

        //-------------PROJECT-------------
        default:
          activeItem = '';
      }
      if (pathLocation.match(/\/project\/(\d+)\/overview/g)) {
        activeItem = MENU_IDS.menuProjectOverview;
      }
      if (pathLocation.match(/\/project\/(\d+)\/property/g)) {
        activeItem = MENU_IDS.menuProjectProperty;
      }
      if (pathLocation.match(/\/project\/(\d+)\/property\/(\d+)\/overview/g)) {
        activeItem = MENU_IDS.menuProjectPropertyOverview;
      }
      if (pathLocation.match(/\/project\/(\d+)\/resident/g)) {
        activeItem = MENU_IDS.menuProjectResident;
      }
      if (pathLocation.match(/\/project\/(\d+)\/department/g)) {
        activeItem = MENU_IDS.menuProjectDepartment;
      }
      if (pathLocation.match(/\/project\/(\d+)\/employee/g)) {
        activeItem = MENU_IDS.menuProjectEmployee;
      }
      if (pathLocation.match(/\/project\/(\d+)\/information/g)) {
        activeItem = MENU_IDS.menuProjectInformation;
      }
      if (pathLocation.match(/\/project\/(\d+)\/structure/g)) {
        activeItem = MENU_IDS.menuProjectStructure;
      }
      if (pathLocation.startsWith(AppRoutes.projectResidentDetail)) {
        activeItem = MENU_IDS.menuProjectResident;
      }
      if (pathLocation.match(/\/project\/(\d+)\/property\/(\d+)\/history/g)) {
        activeItem = MENU_IDS.menuProjectPropertyHistory;
      }

      if (pathLocation.match(/\/project\/(\d+)\/config\/profile/g)) {
        activeItem = MENU_IDS.menuInfoUser;
      }
      if (pathLocation.match(/\/project\/(\d+)\/config\/lang/g)) {
        activeItem = MENU_IDS.menuLanguage;
      }
      if (pathLocation.match(/\/project\/(\d+)\/config\/secure/g)) {
        activeItem = MENU_IDS.menuAccounSecure;
      }

      // if (pathLocation.startsWith(AppRoutes.projectEmployeeDetail)) {
      //   activeItem = MENU_IDS.menuProjectEmployee;
      // }

      setMenuActive(activeItem);
    };

    getMenuActive();
  }, [history.location.pathname]);

  return menuActive;
}

export default useActiveMenu;
