import { actionTypes } from '../actionTypes';

const initialState = {
  loading: false,
  info: null,
  setting: null,
  infoAccountPage: null,
  permission: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_USER:
      return { ...state, loading: false, info: action.payload };
    case actionTypes.SET_LOADING_USER:
      return { ...state, loading: action.payload };
    case actionTypes.SETTING_USER:
      return { ...state, setting: action.payload };
    case actionTypes.SET_INFO_ACCOUNT_PAGE:
      return { ...state, infoAccountPage: action.payload };
    case actionTypes.SET_PERMISSION:
      return { ...state, permission: action.payload };
    default:
      return state;
  }
}
