import { GENDER_CODE, NATIONALITY_CODE, PROCEDURE_CODE, PROPERTY_TYPE_CODE } from 'app-constants';

export const STATUS_ACCOUNT_CODE = {
  ACTIVE: '1',
  DEACTIVE: '0',
};

export const STATUS_ACCOUNT = [
  {
    value: STATUS_ACCOUNT_CODE.ACTIVE,
    label: 'active',
  },
  {
    value: STATUS_ACCOUNT_CODE.DEACTIVE,
    label: 'deactive',
  },
];

export const ACTION_ACCOUNT_CODE = {
  BOARD_PERSONNEL: '0',
  MANAGEMENT_LEVEL_PERSONNEL: '1',
  RESIDENT: '2',
};

export const ACTION_ACCOUNT = [
  {
    value: ACTION_ACCOUNT_CODE.BOARD_PERSONNEL,
    label: 'management_board_personnel',
  },
  {
    value: ACTION_ACCOUNT_CODE.MANAGEMENT_LEVEL_PERSONNEL,
    label: 'centralized_management_level_personnel',
  },
  {
    value: ACTION_ACCOUNT_CODE.RESIDENT,
    label: 'resident',
  },
];

export const DEPARTMENT = [
  {
    value: '1',
    label: 'Phòng kỹ thuật',
  },
  {
    value: '2',
    label: 'Phòng kế toán',
  },
  {
    value: '3',
    label: 'Phòng hành chính',
  },
];
export const PERMISSION = [
  {
    value: '1',
    label: 'management',
  },
  {
    value: '0',
    label: 'permission_employee',
  },
];

export const ROLE_PROPERTY = [
  {
    value: '1',
    label: 'project.resident.edit.roleHouseholder.title',
  },
  {
    value: '0',
    label: 'member',
  },
];

export const GENDER_SEARCH = [
  {
    value: GENDER_CODE.MALE,
    label: 'male',
  },
  {
    value: GENDER_CODE.FEMALE,
    label: 'female',
  },
  {
    value: GENDER_CODE.OTHER,
    label: 'Other',
  },
];

export const NATIONALITY_SEARCH = [
  {
    value: NATIONALITY_CODE.VIET_NAM,
    label: 'text.vietnam',
  },
  {
    value: NATIONALITY_CODE.FOREIGNER,
    label: 'text.foreigner',
  },
];

export const SERVICE_STATE = [
  {
    value: '0',
    label: 'uninhabited',
  },
  {
    value: '1',
    label: 'occupied',
  },
];
export const PROPERTY_TYPE_SEARCH = [
  {
    value: PROPERTY_TYPE_CODE.OTHER,
    label: 'Other',
  },
  {
    value: PROPERTY_TYPE_CODE.VILLA,
    label: 'villa',
  },
  {
    value: PROPERTY_TYPE_CODE.SHOPHOUSE,
    label: 'shophouse',
  },
  {
    value: PROPERTY_TYPE_CODE.MASION,
    label: 'mansion',
  },
  {
    value: PROPERTY_TYPE_CODE.TOWNHOUSE,
    label: 'townhouse',
  },
  {
    value: PROPERTY_TYPE_CODE.LUXURY_BUILDING,
    label: 'luxury_apartment_building',
  },
  {
    value: PROPERTY_TYPE_CODE.ORDINARY_BUILDING,
    label: 'ordinary_apartment_building',
  },
];

export const PROCEDURE_SEARCH = [
  {
    value: PROCEDURE_CODE.IN_PROGRESS,
    label: 'in_progress',
  },
  {
    value: PROCEDURE_CODE.DELAY_PROGRESS,
    label: 'delay_progress',
  },
  {
    value: PROCEDURE_CODE.NOT_YET_TAKEN_OVER,
    label: 'not_yet_taken_over',
  },
  {
    value: PROCEDURE_CODE.PREPARING_TAKEN_OVER,
    label: 'preparing_taking_over',
  },
  {
    value: PROCEDURE_CODE.TAKEN_OVER,
    label: 'taken_over',
  },
];
