export const AppRoutes = {
  //auth
  login: '/login',
  forgot_password: '/forgot-password',
  change_password: '/change-password',
  page403: '/403',
  page404: '/404',

  //config
  config_account: '/config/account',
  config_account_edit: '/config/account/edit',
  config_secure: '/config/secure',
  config_lang: '/config/lang',
  config_sms: '/config/sms',
  config_sms_setting: '/config/sms/setting',
  config_mail: '/config/mail',
  config_mail_setting: '/config/mail/setting',
  config_mail_send: '/config/mail/send',
  config_resident_vip: '/config/resident-vip',
  config_resident_vip_edit: '/config/resident-vip/edit',
  config_real_estate: '/config/real-estate',

  //department
  department: '/department',
  department_detail: '/department/:id',

  //employee
  listEmployee: '/employees',
  employee_detail: '/employees/:id',
  adddEmployee: '/employee/add',
  editEmployee: '/employee/edit',
  uploadEmployee: '/employee/uploadEmployee',

  //project
  project: '/projects',

  projectOverview: (idProject) => `/project/${idProject ?? ':idProject'}/overview`,
  projectProperty: (idProject) => `/project/${idProject ?? ':idProject'}/property`,
  projectPropertyEdit: (idProject, idProperty) =>
    `/project/${idProject ?? ':idProject'}/property/edit/${idProperty ?? ':idProperty'}`,
  projectPropertyAdd: (idProject) => `/project/${idProject ?? ':idProject'}/property/add`,
  projectUploadProperty: (idProject) => `/project/${idProject ?? ':idProject'}/uploadProperty`,
  projectPropertyOverview: (idProject, idProperty) =>
    `/project/${idProject ?? ':idProject'}/property/${idProperty ?? ':idProperty'}/overview`,
  projectPropertyHistory: (idProject, idProperty) =>
    `/project/${idProject ?? ':idProject'}/property/${idProperty ?? ':idProperty'}/history`,

  projectResident: (idProject) => `/project/${idProject ?? ':idProject'}/resident`,
  projectResidentDetail: (idProject, id) => `/project/${idProject ?? ':idProject'}/resident/${id ?? ':id'}`,
  projectResidentAdd: (idProject) => `/project/${idProject ?? ':idProject'}/resident-add`,
  projectResidentEdit: (idProject, id) => `/project/${idProject ?? ':idProject'}/resident-edit/${id ?? ':id'}`,
  projectUploadResident: (idProject, id) => `/project/${idProject ?? ':idProject'}/uploadResident`,

  //project department
  projectDepartment: (idProject) => `/project/${idProject ?? ':idProject'}/department`,
  projectDepartmentDetail: (idProject, id) => `/project/${idProject ?? ':idProject'}/department/${id ?? ':id'}`,

  //project Employee
  projectEmployee: (idProject) => `/project/${idProject ?? ':idProject'}/employee`,
  projectEmployeeDetail: (idProject, id) => `/project/${idProject ?? ':idProject'}/employee/detail/${id ?? ':id'}`,
  projectAdddEmployee: (idProject) => `/project/${idProject ?? ':idProject'}/employee/add`,
  projectEditEmployee: (idProject, id) => `/project/${idProject ?? ':idProject'}/employee/edit/${id ?? ':id'}`,
  projectUploadEmployee: (idProject) => `/project/${idProject ?? ':idProject'}/employee/uploadEmployee`,

  projectInformation: (idProject) => `/project/${idProject ?? ':idProject'}/information`,
  projectInformationEdit: (idProject) => `/project/${idProject ?? ':idProject'}/information/edit`,
  projectStructure: (idProject) => `/project/${idProject ?? ':idProject'}/structure`,

  configProfile: (idProject) => `/project/${idProject ?? ':idProject'}/config/profile`,
  configProfileEdit: (idProject) => `/project/${idProject ?? ':idProject'}/config/profile/edit`,
  configProfileSecure: (idProject) => `/project/${idProject ?? ':idProject'}/config/secure`,
  configProfileLang: (idProject) => `/project/${idProject ?? ':idProject'}/config/lang`,
  //app
  home: '/home',
};
