import { Breadcrumb as BreadcrumbAntd } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

function Breadcrumb({ breadcrumb, className }) {
  const history = useHistory();
  const handleChangeLink = (link) => {
    if (typeof link === 'function') {
      link();
    } else if (link && history.location.pathname !== link) {
      history.push(link);
    }
  };

  return (
    <div className={className}>
      <BreadcrumbAntd>
        {Array.isArray(breadcrumb) &&
          breadcrumb?.length &&
          breadcrumb.map((item, index, arr) => (
            <BreadcrumbAntd.Item
              key={index}
              className={classNames('text-black text-14px cursor-pointer', {
                'opacity-85': index === arr.length - 1,
                'opacity-50': index < arr.length - 1,
              })}
              onClick={() => handleChangeLink(item.link)}
            >
              {item?.title}
            </BreadcrumbAntd.Item>
          ))}
      </BreadcrumbAntd>
    </div>
  );
}

Breadcrumb.propTypes = {
  breadcrumb: PropTypes.array,
  className: PropTypes.string,
};

export default Breadcrumb;
