import PropTypes from 'prop-types';
const AppLayout = ({ children }) => {
  return (
    <div className={'my-container'}>
      <main>
        <div className="content-page">{children}</div>
      </main>
    </div>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node,
};

export default AppLayout;
