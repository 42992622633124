import { lazy, Suspense } from 'react';

import ProgressLoading from 'components/ProgressLoading/ProgressLoading';

const LazyLayout = (importStatement) => {
  const Component = lazy(importStatement);

  return (
    <Suspense fallback={<ProgressLoading />}>
      <Component />
    </Suspense>
  );
};

export default {
  Login: lazy(() => import('./auth/Login')),
  ForgotPassword: lazy(() => import('./auth/ForgotPassword')),
  DepartmentPage: () => LazyLayout(() => import('./department/DepartmentPage')),
  DepartmentDetailPage: () => LazyLayout(() => import('./department/Detail/DepartmentDetailPage')),
  Page403: lazy(() => import('./auth/Page403')),
  Page404: lazy(() => import('./auth/Page404')),

  //employee
  ListEmployee: () => LazyLayout(() => import('./Employee/ListEmployee')),
  EmployeeDetail: () => LazyLayout(() => import('./Employee/Detail/EmployeeDetail')),
  AddEmployee: () => LazyLayout(() => import('./Employee/Add/EmployeeAdd')),
  EditEmployee: () => LazyLayout(() => import('./Employee/Edit/EmployeeEdit')),
  UploadEmployee: () => LazyLayout(() => import('./Employee/UploadEmployee')),

  HomePage: () => LazyLayout(() => import('./Home/HomePage')),
  // config
  ConfigAccount: () => LazyLayout(() => import('./config/Account/AccountPage')),
  ConfigAccountEdit: () => LazyLayout(() => import('./config/Account/Edit/AccountEditPage')),
  ConfigLang: () => LazyLayout(() => import('./config/Language/LanguagePage')),
  ConfigSecure: () => LazyLayout(() => import('./config/Secure/SecurePage')),
  ConfigSMSGateway: () => LazyLayout(() => import('./config/SMSGateway/SMSGatewayPage')),
  ConfigSMSGatewaySetting: () => LazyLayout(() => import('./config/SMSGateway/Setting/SMSGatewaySettingPage')),
  ConfigMailServer: () => LazyLayout(() => import('./config/MailServer/MailServerPage')),
  ConfigMailServerSetting: () => LazyLayout(() => import('./config/MailServer/Setting/MailServerSettingPage')),
  ConfigMailServerSendMail: () => LazyLayout(() => import('./config/MailServer/SendMail/SendMailPage')),
  ConfigResidentVip: () => LazyLayout(() => import('./config/ResidentVip/ResidentVipPage')),
  ConfigResidentVipEdit: () => LazyLayout(() => import('./config/ResidentVip/Edit/ResidentVipEditPage')),
  ConfigTagRealEstate: () => LazyLayout(() => import('./config/TagRealEstate/TagRealEstatePage')),

  //project
  ProjectPage: () => LazyLayout(() => import('./Project/ProjectList/ProjectPage')),
  ProjectDetailPage: () => LazyLayout(() => import('./Project/ProjectList/Detail/ProjectDetail')),
  ProjectEditPage: () => LazyLayout(() => import('./Project/ProjectList/Edit/EditProjectPage')),

  ProjectOverview: () => LazyLayout(() => import('./Project/Overview/OverviewPage')),
  ProjectResidents: () => LazyLayout(() => import('./Project/ResidentManager/ResidentPage')),
  ProjectListResidentDetail: () => LazyLayout(() => import('./Project/ResidentManager/Detail/ResidentDetaiPage')),
  ProjectListResidentAdd: () => LazyLayout(() => import('./Project/ResidentManager/Add/ResidentAddPage')),
  ProjectListResidentEdit: () => LazyLayout(() => import('./Project/ResidentManager/Edit/ResidentEditPage')),
  UploadResident: () => LazyLayout(() => import('./Project/ResidentManager/UploadResident')),

  //project property
  ProjectPropertyPage: () => LazyLayout(() => import('./Project/Property/PropertyPage')),
  ProjectPropertytAdd: () => LazyLayout(() => import('./Project/Property/Add/PropertyAddPage')),
  ProjectPropertyEdit: () => LazyLayout(() => import('./Project/Property/Edit/PropertyEditPage')),
  UploadProperty: () => LazyLayout(() => import('./Project/Property/UploadProperty')),
  ProjectPropertyOverview: () => LazyLayout(() => import('./Project/Property/Detail/Overview/PropertyOverviewPage')),
  ProjectPropertyHistory: () => LazyLayout(() => import('./Project/Property/Detail/History/HistoryPropertyPage')),

  //project employee
  ProjectListEmployee: () => LazyLayout(() => import('./Project/Employee/ListEmployee')),
  ProjectEmployeeDetail: () => LazyLayout(() => import('./Project/Employee/Detail/EmployeeDetail')),
  ProjectAddEmployee: () => LazyLayout(() => import('./Project/Employee/Add/EmployeeAdd')),
  ProjectEditEmployee: () => LazyLayout(() => import('./Project/Employee/Edit/EmployeeEdit')),
  ProjectUploadEmployee: () => LazyLayout(() => import('./Project/Employee/UploadEmployee')),

  //project department
  ProjectDepartmentPage: () => LazyLayout(() => import('./Project/Department/DepartmentPage')),
  ProjectDepartmentDetailPage: () => LazyLayout(() => import('./Project/Department/Detail/DepartmentDetailPage')),

  //project structure
  ProjectStructurePage: () => LazyLayout(() => import('./Project/Structure/StructurePage')),

  // profile

  ConfigProfile: () => LazyLayout(() => import('./config/Account/AccountPage')),
  ConfigProfileEdit: () => LazyLayout(() => import('./config/Account/Edit/AccountEditPage')),
  ConfigProfileLang: () => LazyLayout(() => import('./config/Language/LanguagePage')),
  ConfigProfileSecure: () => LazyLayout(() => import('./config/Secure/SecurePage')),
};
