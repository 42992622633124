// export const LIST_ROLE = {
//   1: ROLE_ADMIN,
//   2: ROLE_CENTRAL,
//   3: ROLE_TOWNSHIP,
//   4: ROLE_HEAD_MANAGEMENT,
//   5: ROLE_MONITOR_SERVICE,
//   6: ROLE_ACCOUNTANT,
//   7: ROLE_TECHNICAL,
//   8: ROLE_CUSTOMER_CARE,
// };

// 1- Admin, 2- Quản lý tập trung, 3- Township, 4- Trưởng ban quản lý, 5- Giám sát dịch vụ,
// 6- Kế toán, 7- Kỹ thuật, 8- Chăm sóc khách hàng

export const ROLE_ADMIN = [
  // Quản lý bộ phận cấp quản lý tập trung
  {
    name: {
      vi: 'Quản lý bộ phận cấp quản lý tập trung',
      en: 'Centralized department management',
    },
    roles: [
      {
        vi: 'Xem danh sách bộ phận',
        en: 'View department list',
      },
      {
        vi: 'Thêm mới bộ phận',
        en: 'Create department',
      },
      {
        vi: 'Chỉnh sửa thông tin bộ phận',
        en: 'Update department information',
      },
      {
        vi: 'Xem chi tiết thông tin bộ phận',
        en: 'View department detail',
      },
      {
        vi: 'Xóa bộ phận',
        en: 'Delete department',
      },
      {
        vi: 'Xem danh sách nhân sự trực thuộc',
        en: 'View list of employee',
      },
      {
        vi: 'Thêm nhân sự trực thuộc',
        en: 'Add employee',
      },
      {
        vi: 'Gỡ nhân sự trực thuộc',
        en: 'Remove employee',
      },
    ],
  },
  // Quản lý nhân sự cấp quản lý tập trung
  {
    name: {
      vi: 'Quản lý nhân sự cấp quản lý tập trung',
      en: 'Centralized employee management',
    },
    roles: [
      {
        vi: 'Xem danh sách nhân sự',
        en: 'View employee list',
      },
      {
        vi: 'Thêm mới nhân sự',
        en: 'Create employee',
      },
      {
        vi: 'Tải lên danh sách nhân sự',
        en: 'Import employee list',
      },
      {
        vi: 'Xuất danh sách nhân sự',
        en: 'Export employee list',
      },
      {
        vi: 'Xem thông tin nhân sự',
        en: 'View employee detail',
      },
      {
        vi: 'Chỉnh sửa thông tin nhân sự',
        en: 'Update employee information',
      },
      {
        vi: 'Kích hoạt/Dừng hoạt động tài khoản nhân sự',
        en: 'Active/Deactive employee account',
      },
      {
        vi: 'Xóa nhân sự',
        en: 'Delete employee',
      },
      {
        vi: 'Đặt lại mật khẩu',
        en: 'Reset password',
      },
    ],
  },

  // Quản lý dự án
  {
    name: {
      vi: 'Quản lý dự án',
      en: 'Project management',
    },
    roles: [
      {
        vi: 'Xem danh sách dự án',
        en: 'View project list',
      },
      {
        vi: 'Xem tổng quan dự án',
        en: 'View project overview',
      },
      {
        vi: 'Xem chi tiết thông tin dự án',
        en: 'View project detail',
      },
      {
        vi: 'Chỉnh sửa thông tin dự án',
        en: 'Update project information',
      },
      {
        vi: 'Tạo cấu trúc dữ liệu dự án',
        en: 'Create project structure',
      },
      {
        vi: 'Xem cấu trúc dữ liệu dự án',
        en: 'View project structure',
      },
      {
        vi: 'Chỉnh sửa cấu trúc dữ liệu dự án',
        en: 'Update project structure',
      },
      {
        vi: 'Tải lên cấu trúc dữ liệu dự án',
        en: 'Import project structure',
      },
      {
        vi: 'Xóa cấu trúc dữ liệu dự án',
        en: 'Delete project structure',
      },
    ],
  },

  // Cấu hình dự án
  {
    name: {
      vi: 'Cấu hình dự án',
      en: 'Configuration',
    },
    roles: [
      {
        vi: 'Xem cấu hình cư dân VIP',
        en: 'View VIP resident configuration',
      },
      {
        vi: 'Thêm mới cấu hình cư dân VIP',
        en: 'Create VIP resident configuration',
      },
      {
        vi: 'Chỉnh sửa cấu hình cư dân VIP',
        en: 'Update VIP resident configuration',
      },
      {
        vi: 'Xem danh sách nhãn bất động sản',
        en: 'View property label list',
      },
      {
        vi: 'Thêm mới nhãn bất động sản',
        en: 'Create property label list',
      },
      {
        vi: 'Chỉnh sửa nhãn bất động sản',
        en: 'Update property label list',
      },
      {
        vi: 'Xóa nhãn bất động sản',
        en: 'Delete property label list',
      },
      {
        vi: 'Xem cấu hình Mail Server',
        en: 'View Mail Server configuration',
      },
      {
        vi: 'Thêm thông tin Mail Server',
        en: 'View Mail Server detail',
      },
      {
        vi: 'Chỉnh sửa thông tin Mail Server',
        en: 'Update Mail Server information',
      },
      {
        vi: 'Gửi thử mail',
        en: 'Send a test email',
      },
      {
        vi: 'Xem cấu hình SMS Gateway',
        en: 'View SMS Gateway configuration',
      },
      {
        vi: 'Thêm thông tin SMS Gateway',
        en: 'Create SMS Gateway',
      },
      {
        vi: 'Chỉnh sửa thông tin SMS Gateway',
        en: 'Update SMS Gateway information',
      },
      {
        vi: 'Thêm mới tin nhắn mẫu',
        en: 'Create message template',
      },
      {
        vi: 'Xóa tin nhắn mẫu',
        en: 'Delete message template',
      },
      {
        vi: 'Gửi thử SMS',
        en: 'Send test SMS',
      },
    ],
  },
  // Quản lý bất động sản
  {
    name: {
      vi: 'Quản lý bất động sản',
      en: 'Property management',
    },
    roles: [
      {
        vi: 'Xem danh sách bất động sản',
        en: 'View property list',
      },
      {
        vi: 'Xem chi tiết thông tin bất động sản',
        en: 'View property detail',
      },
      {
        vi: 'Thêm bất động sản',
        en: 'Create property',
      },
      {
        vi: 'Chỉnh sửa thông tin bất động sản',
        en: 'Update property information',
      },
      {
        vi: 'Xóa bất động sản',
        en: 'Delete property',
      },
      {
        vi: 'Xuất danh sách bất động sản',
        en: 'Export property list',
      },
      {
        vi: 'Gán nhãn bất động sản',
        en: 'Tag property label',
      },
      {
        vi: 'Tải lên danh sách bất động sản',
        en: 'Import property list',
      },
      {
        vi: 'Xem lịch sử bất động sản',
        en: 'View property history',
      },
      {
        vi: 'Xem danh sách thành viên bất động sản',
        en: 'View property member list',
      },
      {
        vi: 'Thêm mới thành viên bất động sản',
        en: 'Add property member',
      },
      {
        vi: 'Chỉnh sửa thành viên bất động sản',
        en: 'Update property member information',
      },
      {
        vi: 'Xem thông tin chi tiết thành viên bất động sản',
        en: 'View property member detail',
      },
      {
        vi: 'Kích hoạt thành viên bất động sản',
        en: 'Active property member',
      },
      {
        vi: 'Hủy kích hoạt thành viên bất động sản',
        en: 'Deactivate property member',
      },
    ],
  },

  // Quản lý cư dân
  {
    name: {
      vi: 'Quản lý cư dân',
      en: 'Resident management',
    },
    roles: [
      {
        vi: 'Xem danh sách cư dân',
        en: 'View resident list',
      },
      {
        vi: 'Xem chi tiết cư dân',
        en: 'View resident detail',
      },
      {
        vi: 'Thêm mới cư dân',
        en: 'Create resident',
      },
      {
        vi: 'Chỉnh sửa thông tin cư dân',
        en: 'Update resident information',
      },
      {
        vi: 'Xuất danh sách cư dân',
        en: 'Export resident list',
      },
      {
        vi: 'Tải lên danh sách cư dân',
        en: 'Import resident list',
      },
      {
        vi: 'Xem danh sách đề xuất',
        en: 'View list of proposals',
      },
      {
        vi: 'Phê duyệt/Từ chối đề xuất',
        en: 'Approval/Refusal proposals',
      },
    ],
  },

  // Quản lý bộ phận cấp ban quản lý
  {
    name: {
      vi: 'Quản lý bộ phận cấp ban quản lý',
      en: 'Department management',
    },
    roles: [
      {
        vi: 'Xem danh sách bộ phận',
        en: 'View department list',
      },
      {
        vi: 'Thêm mới bộ phận',
        en: 'Create department',
      },
      {
        vi: 'Chỉnh sửa thông tin bộ phận',
        en: 'Update department information',
      },
      {
        vi: 'Xem chi tiết thông tin bộ phận',
        en: 'View department detail',
      },
      {
        vi: 'Xóa bộ phận',
        en: 'Delete department',
      },
      {
        vi: 'Xem danh sách nhân sự trực thuộc',
        en: 'View list of employee',
      },
      {
        vi: 'Thêm nhân sự trực thuộc',
        en: 'Add employee',
      },
      {
        vi: 'Gỡ nhân sự trực thuộc',
        en: 'Remove employee',
      },
    ],
  },

  // Quản lý nhân sự cấp ban quản lý
  {
    name: {
      vi: 'Quản lý nhân sự cấp ban quản lý',
      en: 'Employee management',
    },
    roles: [
      {
        vi: 'Xem danh sách nhân sự',
        en: 'View employee list',
      },
      {
        vi: 'Thêm mới nhân sự',
        en: 'Create employee',
      },
      {
        vi: 'Tải lên danh sách nhân sự',
        en: 'Import employee list',
      },
      {
        vi: 'Xuất danh sách nhân sự',
        en: 'Export employee',
      },
      {
        vi: 'Xem thông tin nhân sự',
        en: 'View employee detail',
      },
      {
        vi: 'Chỉnh sửa thông tin nhân sự',
        en: 'Update employee information',
      },
      {
        vi: 'Kích hoạt/Dừng hoạt động tài khoản nhân sự',
        en: 'Active/Deactive employee account',
      },
      {
        vi: 'Xóa nhân sự',
        en: 'Delete employee',
      },
      {
        vi: 'Đặt lại mật khẩu',
        en: 'Reset password',
      },
    ],
  },
];

export const ROLE_CENTRAL = [
  // Quản lý bộ phận cấp quản lý tập trung
  {
    name: {
      vi: 'Quản lý bộ phận cấp quản lý tập trung',
      en: 'Centralized department management',
    },
    roles: [
      {
        vi: 'Xem danh sách bộ phận',
        en: 'View department list',
      },
      {
        vi: 'Xem chi tiết thông tin bộ phận',
        en: 'View department detail',
      },
      {
        vi: 'Xem danh sách nhân sự trực thuộc',
        en: 'View list of employee',
      },
    ],
  },
  // Quản lý nhân sự cấp quản lý tập trung
  {
    name: {
      vi: 'Quản lý nhân sự cấp quản lý tập trung',
      en: 'Centralized employee management',
    },
    roles: [
      {
        vi: 'Xem danh sách nhân sự',
        en: 'View employee list',
      },
    ],
  },

  // Quản lý dự án
  {
    name: {
      vi: 'Quản lý dự án',
      en: 'Project management',
    },
    roles: [
      {
        vi: 'Xem danh sách dự án',
        en: 'View project list',
      },
      {
        vi: 'Xem tổng quan dự án',
        en: 'View project overview',
      },
      {
        vi: 'Xem chi tiết thông tin dự án',
        en: 'View project detail',
      },
    ],
  },
];

export const ROLE_TOWNSHIP = [
  // Quản lý bộ phận cấp quản lý tập trung
  {
    name: {
      vi: 'Quản lý bộ phận cấp quản lý tập trung',
      en: 'Centralized department management',
    },
    roles: [
      {
        vi: 'Xem danh sách bộ phận',
        en: 'View department list',
      },
      {
        vi: 'Thêm mới bộ phận',
        en: 'Create department',
      },
      {
        vi: 'Chỉnh sửa thông tin bộ phận',
        en: 'Update department information',
      },
      {
        vi: 'Xem chi tiết thông tin bộ phận',
        en: 'View department detail',
      },
      {
        vi: 'Xóa bộ phận',
        en: 'Delete department',
      },
      {
        vi: 'Xem danh sách nhân sự trực thuộc',
        en: 'View list of employee',
      },
      {
        vi: 'Thêm nhân sự trực thuộc',
        en: 'Add employee',
      },
      {
        vi: 'Gỡ nhân sự trực thuộc',
        en: 'Remove employee',
      },
    ],
  },

  // Quản lý nhân sự cấp quản lý tập trung
  {
    name: {
      vi: 'Quản lý nhân sự cấp quản lý tập trung',
      en: 'Centralized employee management',
    },
    roles: [
      {
        vi: 'Xem danh sách nhân sự',
        en: 'View employee list',
      },
      {
        vi: 'Thêm mới nhân sự',
        en: 'Create employee',
      },
      {
        vi: 'Tải lên danh sách nhân sự',
        en: 'Import employee list',
      },
      {
        vi: 'Xuất danh sách nhân sự',
        en: 'Export employee',
      },
      {
        vi: 'Xem thông tin nhân sự',
        en: 'View employee detail',
      },
      {
        vi: 'Chỉnh sửa thông tin nhân sự',
        en: 'Update employee information',
      },
      {
        vi: 'Kích hoạt/Dừng hoạt động tài khoản nhân sự',
        en: 'Active/Deactive employee account',
      },
      {
        vi: 'Xóa nhân sự',
        en: 'Delete employee',
      },
      {
        vi: 'Đặt lại mật khẩu',
        en: 'Reset password',
      },
    ],
  },

  // Quản lý dự án

  {
    name: {
      vi: 'Quản lý dự án',
      en: 'Project management',
    },
    roles: [
      {
        vi: 'Xem danh sách dự án',
        en: 'View project list',
      },
      {
        vi: 'Xem tổng quan dự án',
        en: 'View project overview',
      },
      {
        vi: 'Xem chi tiết thông tin dự án',
        en: 'View project detail',
      },
      {
        vi: 'Chỉnh sửa thông tin dự án',
        en: 'Update project information',
      },
      {
        vi: 'Tạo cấu trúc dữ liệu dự án',
        en: 'Create project structure',
      },
      {
        vi: 'Xem cấu trúc dữ liệu dự án',
        en: 'View project structure',
      },
      {
        vi: 'Chỉnh sửa cấu trúc dữ liệu dự án',
        en: 'Update project structure',
      },
      {
        vi: 'Tải lên cấu trúc dữ liệu dự án',
        en: 'Import project structure',
      },
    ],
  },

  // Cấu hình dự án
  {
    name: {
      vi: 'Cấu hình dự án',
      en: 'Configuration',
    },
    roles: [
      {
        vi: 'Xem cấu hình cư dân VIP',
        en: 'View VIP resident configuration',
      },
      {
        vi: 'Thêm mới cấu hình cư dân VIP',
        en: 'Create VIP resident configuration',
      },
      {
        vi: 'Chỉnh sửa cấu hình cư dân VIP',
        en: 'Update VIP resident configuration',
      },
      {
        vi: 'Xem danh sách nhãn bất động sản',
        en: 'View property label list',
      },
      {
        vi: 'Thêm mới nhãn bất động sản',
        en: 'Create property label list',
      },
      {
        vi: 'Chỉnh sửa nhãn bất động sản',
        en: 'Update property label list',
      },
      {
        vi: 'Xóa nhãn bất động sản',
        en: 'Delete property label list',
      },
    ],
  },

  // Quản lý bất động sản
  {
    name: {
      vi: 'Quản lý bất động sản',
      en: 'Property management',
    },
    roles: [
      {
        vi: 'Xem danh sách bất động sản',
        en: 'View property list',
      },
      {
        vi: 'Xem chi tiết thông tin bất động sản',
        en: 'View property detail',
      },
      {
        vi: 'Thêm bất động sản',
        en: 'Create property',
      },
      {
        vi: 'Chỉnh sửa thông tin bất động sản',
        en: 'Update property information',
      },
      {
        vi: 'Xuất danh sách bất động sản',
        en: 'Export property list',
      },
      {
        vi: 'Tải lên danh sách bất động sản',
        en: 'Import property list',
      },
    ],
  },

  // Quản lý cư dân
  {
    name: {
      vi: 'Quản lý cư dân',
      en: 'Resident management',
    },
    roles: [
      {
        vi: 'Xem danh sách cư dân',
        en: 'View resident list',
      },
    ],
  },

  // Quản lý bộ phận cấp ban quản lý
  {
    name: {
      vi: 'Quản lý bộ phận cấp ban quản lý',
      en: 'Department management',
    },
    roles: [
      {
        vi: 'Xem danh sách bộ phận',
        en: 'View department list',
      },
      {
        vi: 'Thêm mới bộ phận',
        en: 'Create department',
      },
      {
        vi: 'Chỉnh sửa thông tin bộ phận',
        en: 'Update department information',
      },
      {
        vi: 'Xem chi tiết thông tin bộ phận',
        en: 'View department detail',
      },
      {
        vi: 'Xóa bộ phận',
        en: 'Delete department',
      },
      {
        vi: 'Xem danh sách nhân sự trực thuộc',
        en: 'View list of employee',
      },
      {
        vi: 'Thêm nhân sự trực thuộc',
        en: 'Add employee',
      },
      {
        vi: 'Gỡ nhân sự trực thuộc',
        en: 'Remove employee',
      },
    ],
  },

  // Quản lý nhân sự cấp ban quản lý
  {
    name: {
      vi: 'Quản lý nhân sự cấp ban quản lý',
      en: 'Employee management',
    },
    roles: [
      {
        vi: 'Xem danh sách nhân sự',
        en: 'View list of employee',
      },
      {
        vi: 'Thêm mới nhân sự',
        en: 'Create employee',
      },
      {
        vi: 'Tải lên danh sách nhân sự',
        en: 'Import employee list',
      },
      {
        vi: 'Xuất danh sách nhân sự',
        en: 'Export employee',
      },
      {
        vi: 'Xem thông tin nhân sự',
        en: 'View employee detail',
      },
      {
        vi: 'Chỉnh sửa thông tin nhân sự',
        en: 'Update employee information',
      },
      {
        vi: 'Kích hoạt/Dừng hoạt động tài khoản nhân sự',
        en: 'Active/Deactive employee account',
      },
      {
        vi: 'Xóa nhân sự',
        en: 'Delete employee',
      },
      {
        vi: 'Đặt lại mật khẩu',
        en: 'Reset password',
      },
    ],
  },
];

export const ROLE_HEAD_MANAGEMENT = [
  // Quản lý dự án
  {
    name: {
      vi: 'Quản lý dự án',
      en: 'Project management',
    },
    roles: [
      {
        vi: 'Xem tổng quan dự án',
        en: 'View project overview',
      },
      {
        vi: 'Xem chi tiết thông tin dự án',
        en: 'View project detail',
      },
    ],
  },

  // Quản lý bất động sản
  {
    name: {
      vi: 'Quản lý bất động sản',
      en: 'Property management',
    },
    roles: [
      {
        vi: 'Xem danh sách bất động sản',
        en: 'View property list',
      },
      {
        vi: 'Xem chi tiết thông tin bất động sản',
        en: 'View property detail',
      },
      {
        vi: 'Xuất danh sách bất động sản',
        en: 'Export property list',
      },
      {
        vi: 'Gán nhãn bất động sản',
        en: 'Tag property label',
      },
      {
        vi: 'Xem lịch sử bất động sản',
        en: 'View property history',
      },
      {
        vi: 'Xem danh sách thành viên bất động sản',
        en: 'View property member list',
      },
      {
        vi: 'Thêm mới thành viên bất động sản',
        en: 'Add property member',
      },
      {
        vi: 'Chỉnh sửa thành viên bất động sản',
        en: 'Update property member information',
      },
      {
        vi: 'Xem thông tin chi tiết thành viên bất động sản',
        en: 'View property member detail',
      },
      {
        vi: 'Kích hoạt thành viên bất động sản',
        en: 'Active property member',
      },
      {
        vi: 'Hủy kích hoạt thành viên bất động sản',
        en: 'Deactivate property member',
      },
    ],
  },

  // Quản lý cư dân
  {
    name: {
      vi: 'Quản lý cư dân',
      en: 'Resident management',
    },
    roles: [
      {
        vi: 'Xem danh sách cư dân',
        en: 'View resident list',
      },
      {
        vi: 'Xem chi tiết cư dân',
        en: 'View resident detail',
      },
      {
        vi: 'Thêm mới cư dân',
        en: 'Create resident',
      },
      {
        vi: 'Chỉnh sửa thông tin cư dân',
        en: 'Update resident information',
      },

      {
        vi: 'Tải lên danh sách cư dân',
        en: 'Import resident list',
      },
      {
        vi: 'Xem danh sách đề xuất',
        en: 'View list of proposals',
      },
      {
        vi: 'Phê duyệt/Từ chối đề xuất',
        en: 'Approval/Refusal proposals',
      },
    ],
  },

  // Quản lý bộ phận cấp ban quản lý
  {
    name: {
      vi: 'Quản lý bộ phận cấp ban quản lý',
      en: 'Department management',
    },
    roles: [
      {
        vi: 'Xem danh sách bộ phận',
        en: 'View department list',
      },
      {
        vi: 'Thêm mới bộ phận',
        en: 'Create department',
      },
      {
        vi: 'Chỉnh sửa thông tin bộ phận',
        en: 'Update department information',
      },
      {
        vi: 'Xem chi tiết thông tin bộ phận',
        en: 'View department detail',
      },
      {
        vi: 'Xóa bộ phận',
        en: 'Delete department',
      },
      {
        vi: 'Xem danh sách nhân sự trực thuộc',
        en: 'View list of employee',
      },
      {
        vi: 'Thêm nhân sự trực thuộc',
        en: 'Add employee',
      },
      {
        vi: 'Gỡ nhân sự trực thuộc',
        en: 'Remove employee',
      },
    ],
  },

  // Quản lý nhân sự cấp ban quản lý
  {
    name: {
      vi: 'Quản lý nhân sự cấp ban quản lý',
      en: 'Employee management',
    },
    roles: [
      {
        vi: 'Xem danh sách nhân sự',
        en: 'View list of employee',
      },
      {
        vi: 'Thêm mới nhân sự',
        en: 'Create employee',
      },
      {
        vi: 'Tải lên danh sách nhân sự',
        en: 'Import employee list',
      },
      {
        vi: 'Xuất danh sách nhân sự',
        en: 'Export employee',
      },
      {
        vi: 'Xem thông tin nhân sự',
        en: 'View employee detail',
      },
      {
        vi: 'Chỉnh sửa thông tin nhân sự',
        en: 'Update employee information',
      },
      {
        vi: 'Kích hoạt/Dừng hoạt động tài khoản nhân sự',
        en: 'Active/Deactive employee account',
      },
      {
        vi: 'Xóa nhân sự',
        en: 'Delete employee',
      },
      {
        vi: 'Đặt lại mật khẩu',
        en: 'Reset password',
      },
    ],
  },
];
export const ROLE_MONITOR_SERVICE = [
  // Quản lý dự án
  {
    name: {
      vi: 'Quản lý dự án',
      en: 'Project management',
    },
    roles: [
      {
        vi: 'Xem tổng quan dự án',
        en: 'View project overview',
      },
      {
        vi: 'Xem chi tiết thông tin dự án',
        en: 'View project detail',
      },
    ],
  },

  // Quản lý bất động sản
  {
    name: {
      vi: 'Quản lý bất động sản',
      en: 'Property management',
    },
    roles: [
      {
        vi: 'Xem danh sách bất động sản',
        en: 'View property list',
      },
      {
        vi: 'Xem chi tiết thông tin bất động sản',
        en: 'View property detail',
      },
      {
        vi: 'Xuất danh sách bất động sản',
        en: 'Export property list',
      },
      {
        vi: 'Gán nhãn bất động sản',
        en: 'Tag property label',
      },
      {
        vi: 'Xem lịch sử bất động sản',
        en: 'View property history',
      },
    ],
  },
];
export const ROLE_ACCOUNTANT = [
  // Quản lý dự án
  {
    name: {
      vi: 'Quản lý dự án',
      en: 'Project management',
    },
    roles: [
      {
        vi: 'Xem tổng quan dự án',
        en: 'View project overview',
      },
      {
        vi: 'Xem chi tiết thông tin dự án',
        en: 'View project detail',
      },
    ],
  },

  // Quản lý bất động sản
  {
    name: {
      vi: 'Quản lý bất động sản',
      en: 'Property management',
    },
    roles: [
      {
        vi: 'Xem danh sách bất động sản',
        en: 'View property list',
      },
      {
        vi: 'Xem chi tiết thông tin bất động sản',
        en: 'View property detail',
      },
      {
        vi: 'Xuất danh sách bất động sản',
        en: 'Export property list',
      },
      {
        vi: 'Gán nhãn bất động sản',
        en: 'Tag property label',
      },

      {
        vi: 'Xem lịch sử bất động sản',
        en: 'View property history',
      },
    ],
  },
];
export const ROLE_TECHNICAL = [
  // Quản lý dự án
  {
    name: {
      vi: 'Quản lý dự án',
      en: 'Project management',
    },
    roles: [
      {
        vi: 'Xem tổng quan dự án',
        en: 'View project overview',
      },
      {
        vi: 'Xem chi tiết thông tin dự án',
        en: 'View project detail',
      },
    ],
  },

  // Quản lý bất động sản
  // {
  //   name: {
  //     vi: 'Quản lý bất động sản',
  //     en: 'Property management',
  //   },
  //   roles: [
  //     {
  //       vi: 'Xem danh sách bất động sản',
  //       en: 'View property list',
  //     },
  //     {
  //       vi: 'Xem chi tiết thông tin bất động sản',
  //       en: 'View property detail',
  //     },
  //   ],
  // },
];
export const ROLE_CUSTOMER_CARE = [
  // Quản lý dự án
  {
    name: {
      vi: 'Quản lý dự án',
      en: 'Project management',
    },
    roles: [
      {
        vi: 'Xem tổng quan dự án',
        en: 'View project overview',
      },
      {
        vi: 'Xem chi tiết thông tin dự án',
        en: 'View project detail',
      },
    ],
  },

  // Quản lý bất động sản
  {
    name: {
      vi: 'Quản lý bất động sản',
      en: 'Property management',
    },
    roles: [
      {
        vi: 'Xem danh sách bất động sản',
        en: 'View property list',
      },
      {
        vi: 'Xem chi tiết thông tin bất động sản',
        en: 'View property detail',
      },
      {
        vi: 'Xuất danh sách bất động sản',
        en: 'Export property list',
      },
      {
        vi: 'Gán nhãn bất động sản',
        en: 'Tag property label',
      },
      {
        vi: 'Xem lịch sử bất động sản',
        en: 'View property history',
      },
      {
        vi: 'Xem danh sách thành viên bất động sản',
        en: 'View property member list',
      },
      {
        vi: 'Thêm mới thành viên bất động sản',
        en: 'Add property member',
      },
      {
        vi: 'Chỉnh sửa thành viên bất động sản',
        en: 'Update property member information',
      },
      {
        vi: 'Xem thông tin chi tiết thành viên bất động sản',
        en: 'View property member detail',
      },
      {
        vi: 'Kích hoạt thành viên bất động sản',
        en: 'Active property member',
      },
      {
        vi: 'Hủy kích hoạt thành viên bất động sản',
        en: 'Deactivate property member',
      },
    ],
  },
  // Quản lý cư dân
  {
    name: {
      vi: 'Quản lý cư dân',
      en: 'Resident management',
    },
    roles: [
      {
        vi: 'Xem danh sách cư dân',
        en: 'View resident list',
      },
      {
        vi: 'Xem chi tiết cư dân',
        en: 'View resident detail',
      },
      {
        vi: 'Thêm mới cư dân',
        en: 'Create resident',
      },
      {
        vi: 'Chỉnh sửa thông tin cư dân',
        en: 'Update resident information',
      },
      {
        vi: 'Tải lên danh sách cư dân',
        en: 'Import resident list',
      },
      {
        vi: 'Xem danh sách đề xuất',
        en: 'View list of proposals',
      },
      {
        vi: 'Phê duyệt/Từ chối đề xuất',
        en: 'Approval/Refusal proposals',
      },
    ],
  },
];
