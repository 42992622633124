import { CONFIG_OTP_CODE, LANG_CODE } from 'app-constants';
import { AppRoutes } from 'app-constants/AppRoutes';
import { notifyError, notifySuccess } from 'components/Notification/Notification';
import { getMessageErr } from 'helpers/messageError';
import { getRefreshToken, getUserName } from 'helpers/storage';
import Utils from 'helpers/Utils';
import i18n from 'i18n';
import { appI18n } from 'i18n';
import apiClient from 'services/apiClient';
import { actionTypes } from '../actionTypes';

const handleError = (error) => {
  if (error?.code !== '504') {
    const messageErr = getMessageErr(error);
    notifyError(messageErr?.message);
  }
  return Promise.reject(error);
};

const handleRember = (remember, username, password) => {
  localStorage.setItem('userName', username);
  if (remember) {
    localStorage.setItem('remember', true);
    localStorage.setItem('p', password);
  } else {
    localStorage.setItem('remember', false);
    localStorage.removeItem('p');
  }
};

const handleSaveToken = (token, refreshToken) => {
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
};

const onLogin = (username, password, remember, history) => async (dispatch) => {
  try {
    handleRember(remember, username, password);
    const response = await apiClient.post('/auth/web/login', { username, password });
    if (response?.result === 1) {
      const user = response?.data;
      if (!user?.userMfa && !user?.hasFirstLogin && user?.access_token) {
        handleSaveToken(`${user.token_type} ${user.access_token}`, user.refresh_token);
        await dispatch(checkPermission(history));
        if (user?.messageChangePass) {
          notifyError(user?.messageChangePass);
        }
        notifySuccess(appI18n.t('login_success'));
      }
      dispatch(setUser(user));
    }

    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const verifyOTP = (username, verificationCode, history) => async (dispatch) => {
  try {
    const response = await apiClient.post('/auth/web/otp-verification', { username, verificationCode });
    if (response?.result === 1 && response?.data?.access_token) {
      handleSaveToken(`${response?.data?.token_type} ${response?.data?.access_token}`, response?.data?.refresh_token);
      notifySuccess(i18n.t('login_success'));
      await dispatch(checkPermission(history));
      return true;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const reSendOTP = async (username) => {
  try {
    const response = await apiClient.post('/auth/web/resend-otp-verification', { username });
    if (response?.result === 1) {
      // notifySuccess(response?.message);
      return response;
    }
  } catch (error) {
    handleError(error);
  }
};

const forgotPassword = async (username) => {
  try {
    return await apiClient.post('/auth/web/forget-password', { username });
  } catch (error) {
    handleError(error);
  }
};

const verifyOTPForgotPassword = async (username, verificationCode) => {
  try {
    const response = await apiClient.post('/auth/web/forget-password-otp-verification', { username, verificationCode });
    return response;
  } catch (error) {
    handleError(error);
  }
};

const otpConfig = async (dataPost, callback) => {
  try {
    const response = await apiClient.post('/auth/web/otp-config', dataPost);
    if (dataPost?.mfaType === CONFIG_OTP_CODE.PHONE) {
      notifySuccess(Utils.capitalizeFirstLetter(i18n.t('configOtpVia', { name: i18n.t('PhoneNumber') })));
    } else {
      notifySuccess(Utils.capitalizeFirstLetter(i18n.t('configOtpVia', { name: i18n.t('Email') })));
    }
    callback && callback();
    return response;
  } catch (error) {
    handleError(error);
  }
};

const changePasswordForgotPassword = async (username, password, retypePassword, history) => {
  try {
    const response = await apiClient.post('/auth/web/forget-password-change-password', {
      username,
      password,
      retypePassword,
    });
    if (response?.result === 1) {
      notifySuccess(i18n.t('message.change_password_successfully'), i18n.t('message.use_new_password_login'));
      history.push(AppRoutes.login);
    }
  } catch (error) {
    handleError(error);
  }
};

const changePassword = async (username, currentPassword, newPassword, retypePassword, history) => {
  try {
    const response = await apiClient.post('/auth/web/change-password', {
      username,
      currentPassword,
      newPassword,
      retypePassword,
      isPassPresent: 1,
    });
    if (response?.result === 1) {
      notifySuccess(i18n.t('message.change_password_successfully'));
      return true;
    } else {
      notifyError(response?.message);
      return false;
    }
  } catch (error) {
    handleError(error);
  }
};

const checkFiveLatestPassword = async (username, password) => {
  try {
    const response = await apiClient.post('/auth/web/check-5-latest-password', {
      username,
      password,
    });
    if (response?.result === 1) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    handleError(error);
  }
};

const handleLogout = (history) => {
  ['token', 'refreshToken'].forEach((item) => {
    localStorage.removeItem(item);
  });
  history.push(AppRoutes.login);
};

const onLogout = async (history) => {
  try {
    const response = await apiClient.post('/auth/web/logout', {
      refreshToken: getRefreshToken(),
    });

    if (response?.result === 1) {
      handleLogout(history);
    }
  } catch (error) {
    handleLogout(history);
  }
};

const enable2FA = async (username, status) => {
  try {
    const response = await apiClient.post('/auth/web/enable-2fa', {
      username,
      status,
    });

    if (response?.result === 1) {
      notifySuccess(Utils.capitalizeFirstLetter(i18n.t('2FASuccess', { name: i18n.t(status ? 'on' : 'off') })));
      return response;
    }
  } catch (error) {
    handleError(error);
  }
};

const updateLanguage = async (username, language) => {
  try {
    const response = await apiClient.post('/auth/web/update-language', {
      username,
      language,
    });
    if (response?.result === 1) {
      if (Object.keys(LANG_CODE).includes(language)) {
        localStorage.setItem('lang', language);
        window.location.reload();
        // notifySuccess(Utils.capitalizeFirstLetter(i18n.t('changeLanguage')));
      }
    }
  } catch (error) {
    handleError(error);
  }
};

const checkPermission = (history) => async (dispatch) => {
  try {
    const user = await dispatch(getAccountSetting());
    if (!user) {
      history.push(AppRoutes.page403);
      return;
    }
    const permission = await dispatch(getPermission());
    if ((Array.isArray(permission) && permission.length === 0) || !permission) {
      history.push(AppRoutes.page403);
    }
  } catch (error) {}
};

const getAccountSetting = () => async (dispatch) => {
  try {
    const response = await apiClient.get('/auth/web/get-account-setting', {
      params: {
        username: getUserName(),
      },
    });
    if (response?.result === 1) {
      dispatch(setUserSetting(response?.data));
    }
    return response?.data;
  } catch (error) {}
};

const getUserDepartmentSite = (username) => async (dispatch) => {
  try {
    const response = await apiClient.get('/web/user/get-user-department-site', {
      params: {
        username,
      },
    });
    dispatch(setInfoAccountPage(response));
  } catch (error) {
    handleError(error);
  }
};

const changeAvatar = async (file, username) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('username', username);
    const response = await apiClient.put('/auth/web/change-avatar', formData);
    if (response?.result === 1) {
      return response;
    }
  } catch (error) {
    handleError(error);
  }
};

const updateUser = async (dataUpdate) => {
  try {
    const response = await apiClient.put('/auth/web/update-user', dataUpdate);
    if (response?.result === 1) {
      notifySuccess(i18n.t('editing_personal_information_successfully'));
      return true;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const getPermission = () => async (dispatch) => {
  try {
    const response = await apiClient.get('/role-by-user/');

    const convertRole =
      Array.isArray(response?.data) &&
      response.data.reduce((acc, curr) => {
        if (curr.type === 1) {
          if (acc?.QLTT && Array.isArray(acc?.QLTT) && curr?.id) {
            acc.QLTT = [...acc.QLTT, curr?.id];
          } else if (!acc?.QLTT && curr?.id) {
            acc.QLTT = [curr?.id];
          }
        } else if (curr.type === 0) {
          if (acc?.BQL && typeof acc.BQL === 'object' && curr?.id && curr?.siteId) {
            const valueBQL = acc.BQL;
            if (valueBQL.hasOwnProperty(curr.siteId)) {
              valueBQL[curr?.siteId] = [...valueBQL[curr?.siteId], curr?.id];
            } else {
              valueBQL[curr?.siteId] = [curr?.id];
            }
            acc.BQL = valueBQL;
          } else if (!acc?.BQL && curr?.id && curr?.siteId) {
            acc.BQL = {
              [`${curr?.siteId}`]: [curr?.id],
            };
          }
        }
        return acc;
      }, {});
    dispatch(setPermission(convertRole));

    return response?.data;
  } catch (error) {
    return handleError(error);
  }
};

export const setUser = (user) => ({
  type: actionTypes.SET_USER,
  payload: user,
});

export const setPermission = (permission) => ({
  type: actionTypes.SET_PERMISSION,
  payload: permission,
});

export const setUserSetting = (userSetting) => ({
  type: actionTypes.SETTING_USER,
  payload: userSetting,
});

export const setInfoAccountPage = (infoUser) => ({
  type: actionTypes.SET_INFO_ACCOUNT_PAGE,
  payload: infoUser,
});

const AUTH_ACTION = {
  onLogin,
  verifyOTP,
  reSendOTP,
  forgotPassword,
  verifyOTPForgotPassword,
  otpConfig,
  changePasswordForgotPassword,
  changePassword,
  checkFiveLatestPassword,
  onLogout,
  enable2FA,
  updateLanguage,
  getAccountSetting,
  getUserDepartmentSite,
  changeAvatar,
  updateUser,
  handleRember,
  getPermission,
};

export default AUTH_ACTION;
