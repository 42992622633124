import { Spin } from 'antd';
import './ProgressLoading.scss';
import ReactDOM from 'react-dom';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

function ProgressLoading() {
  const { t } = useTranslation();

  return ReactDOM.createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1 }}
      className="ProgressLoading"
    >
      <div className="content-loading">
        <Spin size="large" />
        <p className="mt-12px z-50"> {t('processing')}...</p>
      </div>
    </motion.div>,
    document.querySelector('body'),
  );
}

export default ProgressLoading;
