export const actionTypes = {
  //user
  LOGIN: 'user/LOGIN',
  SET_USER: 'user/SET_USER',
  SET_PERMISSION: 'user/SET_PERMISSION',
  SET_LOADING_USER: 'user/SET_LOADING_USER',
  SETTING_USER: 'user/SETTING_USER',

  DEPARTMENT: 'department/getDepartments',

  SET_EMPLOYEE: 'employee/setEmployees',
  SET_INFO_ACCOUNT_PAGE: 'user/SET_INFO_ACCOUNT_PAGE',

  //project
  SET_BREADCRUMB_PROJECT: 'project/setBreadcrumb',
  SET_CURRENT_PROJECT: 'SET_CURRENT_PROJECT',

  // menu
  SET_MENU: 'SET_MENU',

  //theme
  SET_THEME: 'SET_THEME',

  //employee
  SET_FILE_UPLOAD: 'fileUpload/SET_FILE_UPLOAD',

  //common
  LOADING: 'LOADING',

  SET_CURRENT_PROPERTY: 'SET_CURRENT_PROPERTY',
};
