import { actionTypes } from '../actionTypes';

const initialState = {
  breadCrumb: [],
  currentProject: null,
};

export default function projectReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_BREADCRUMB_PROJECT:
      return { ...state, breadCrumb: action.payload };
    case actionTypes.SET_CURRENT_PROJECT:
      return { ...state, currentProject: action.payload };
    default:
      return state;
  }
}
