import { notifyError, notifySuccess } from 'components/Notification/Notification';
import { getMessageErr } from 'helpers/messageError';
import apiClient from 'services/apiClient';
import i18n from 'i18n';
import { store } from 'redux/store';
import { actionTypes } from 'redux/actionTypes';
import Utils from 'helpers/Utils';

const handleError = (error) => {
  if (error?.code !== '504') {
    const messageErr = getMessageErr(error);
    notifyError(messageErr?.message);
  }
  return Promise.reject(error);
};

export const setCurrentProperty = (property) => ({
  type: actionTypes.SET_CURRENT_PROPERTY,
  payload: property,
});

//Async action
const getPropertiesByProject = async (siteId, params) => {
  try {
    const response = await apiClient.get(`property/find-property/${siteId}`, { params });
    if (response?.result === 1) {
      const properties = response?.data;
      return properties;
    }
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getProperties = async (params) => {
  try {
    const response = await apiClient.get(`/property/`, {
      params,
    });
    if (response?.result === 1) {
      const properties = response?.data;
      return properties;
    }
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getProperty = async (id) => {
  try {
    const response = await apiClient.get(`/property/${id}`);
    if (response?.result === 1) {
      const property = response?.data;
      store.dispatch(setCurrentProperty(property));
      return property;
    }
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const deleteProperty = async (propertyId) => {
  try {
    const response = await apiClient.delete(`/property/${propertyId}`);
    if (response?.result === 1) {
      notifySuccess(i18n.t('deleteSuccess', { name: i18n.t('property_msg') }));
      return true;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const insertLabel = async (propertyId, labels) => {
  try {
    const response = await apiClient.put(`/property/${propertyId}/insert-label`, labels);
    if (response?.result === 1) {
      notifySuccess(
        i18n.t('saveSuccess', {
          name: i18n.t('label', {
            suffix: '',
          }),
        }),
      );
      return true;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const insertPropertiesLabel = async (body) => {
  try {
    const response = await apiClient.put('/site/insert-properties-label', body);
    if (response?.result === 1) {
      notifySuccess(i18n.t('insertSuccess', { name: Utils.lowerCaseFirstLetter(i18n.t('property_label')) }));
      return true;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const addProperty = async (dataPost) => {
  try {
    const response = await apiClient.post('/property/add', dataPost);
    if (response?.result === 1) {
      notifySuccess(i18n.t('addSuccess', { name: i18n.t('property_msg') }));
      return true;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const updateProperty = async (propertyId, dataPost) => {
  try {
    const response = await apiClient.put(`/property/${propertyId}`, dataPost);
    if (response?.result === 1) {
      notifySuccess(i18n.t('editSuccess', { name: i18n.t('property_msg') }));
      return true;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const getPropertyHistory = async (id, params) => {
  try {
    const response = await apiClient.get(`/property/${id}/history`, { params });
    if (response?.result === 1) {
      const property = response?.data;
      return property;
    }
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getPropertyResidence = async (id) => {
  try {
    const response = await apiClient.get(`/property/${id}/find-all-residence`);
    if (response?.result === 1) {
      const property = response?.data;
      return property;
    }
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const exportProperty = async (isAll, ids, idProject) => {
  try {
    const response = await apiClient.get(`property/export?isAll=${isAll}&siteId=${idProject}&ids=${ids.toString()}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
    return response;
  } catch (error) {
    handleError(error);
  }
};

const importProperty = async (file, idProject) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('siteId', idProject);

    const response = await apiClient.post('property/import', formData);
    if (response?.result === 1) {
      return response.data;
    }
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const deletePropertyResidence = async (propertyId, residenceId) => {
  try {
    const response = await apiClient.get(`/property/${propertyId}/delete-residence/${residenceId}`);
    if (response?.result === 1) {
      notifySuccess(i18n.t('deleteSuccess', { name: Utils.lowerCaseFirstLetter(i18n.t('resident', { suffix: '' })) }));
      return true;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const findResidence = async (keyword) => {
  try {
    const response = await apiClient.get(`/residence/find?keyword=${keyword}`);
    if (response?.result === 1) {
      return response?.data;
    }
  } catch (error) {}
};

const insertOldResidence = async (idProperty, params) => {
  try {
    const response = await apiClient.post(`/property/${idProperty}/insert-old-residence/`, params);
    if (response?.result === 1) {
      notifySuccess(i18n.t('addSuccess', { name: Utils.lowerCaseFirstLetter(i18n.t('resident', { suffix: '' })) }));
      return true;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const insertNewResidence = async (idProperty, params) => {
  try {
    const response = await apiClient.post(`/property/${idProperty}/insert-new-residence`, params);
    if (response?.result === 1) {
      notifySuccess(i18n.t('saveSuccess', { name: Utils.lowerCaseFirstLetter(i18n.t('resident', { suffix: '' })) }));
      return true;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};
const updateResidence = async (idProperty, params) => {
  try {
    const response = await apiClient.put(`/property/${idProperty}/update-residence/${params.id}`, params);
    if (response?.result === 1) {
      notifySuccess(i18n.t('editSuccess', { name: Utils.lowerCaseFirstLetter(i18n.t('resident', { suffix: '' })) }));
      return true;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const updateStatusResidence = async (propertyId, residenceId, status) => {
  try {
    const response = await apiClient.put(
      `/property/${propertyId}/residence-status/${residenceId}?status=${status}`,
      {},
    );
    if (response?.result === 1) {
      notifySuccess(
        Utils.capitalizeFirstLetter(
          i18n.t(status ? 'resident_status_active_success' : 'resident_status_deactive_success'),
        ),
      );
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const PROPERTY_ACTION = {
  getPropertiesByProject,
  getProperties,
  getProperty,
  deleteProperty,
  insertLabel,
  addProperty,
  updateProperty,
  getPropertyHistory,
  exportProperty,
  importProperty,
  getPropertyResidence,
  deletePropertyResidence,
  findResidence,
  insertOldResidence,
  insertNewResidence,
  updateResidence,
  insertPropertiesLabel,
  setCurrentProperty,
  updateStatusResidence,
};

export default PROPERTY_ACTION;
