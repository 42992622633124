import { AppRoutes } from 'app-constants/AppRoutes';
import { ROLE_VIEW_LIST, ROLE_VIEW_LIST_PROJECT } from 'app-constants/permission';
import ChangeLocales from 'components/ChangeLocales/ChangeLocales';
import NotificationCount from 'components/NotificationCount/NotificationCount';
import UserMenu from 'components/UserMenu/UserMenu';
import usePermission from 'hooks/usePermission';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

function MenuTop() {
  const history = useHistory();
  const { permission } = useSelector((state) => state.user) ?? {};
  const { t } = useTranslation();
  const handleChangeLink = (link) => {
    if (link) {
      history.push(link);
    }
  };

  const { allRoles: roleQLTT } = usePermission({
    listPermissionId: permission?.QLTT,
    siteId: null,
  });

  const MENU_TOP = useMemo(() => {
    return [
      {
        isShow: roleQLTT?.LIST_PROJECT?.includes(ROLE_VIEW_LIST_PROJECT),
        title: 'project_management',
        link: AppRoutes.project,
      },
      {
        isShow: roleQLTT?.DEPARTMENT_CENTRAL?.includes(ROLE_VIEW_LIST),
        title: 'centralized_department_management',
        link: AppRoutes.department,
      },
      {
        isShow: roleQLTT?.EMPLOYEE_CENTRAL?.includes(ROLE_VIEW_LIST),
        title: 'centralized_employee_management',
        link: AppRoutes.listEmployee,
      },
      {
        isShow: true,
        title: 'configuration',
        link: AppRoutes.config_account,
      },
    ];
  }, [roleQLTT]);

  return (
    <div className="h-48px bg-910D11 text-white flex justify-between pl-16px pr-28px select-none">
      {/* left */}
      <div className="flex items-center">
        <div className="w-20px h-20px bg-237804 mr-20px"></div>
        <ul className="flex items-center gap-40px">
          {MENU_TOP.map(
            (menu, index) =>
              menu?.isShow && (
                <li className="cursor-pointer" key={index} onClick={() => handleChangeLink(menu.link)}>
                  {t(menu.title)}
                </li>
              ),
          )}
        </ul>
        <ChangeLocales />
      </div>
      {/* right */}
      <div className="flex items-center gap-30px">
        <NotificationCount />
        <UserMenu />
      </div>
    </div>
  );
}

export default MenuTop;
