import ChangeLocales from 'components/ChangeLocales/ChangeLocales';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const LoginLayout = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className="layout-login flex-center w-full h-full relative overflow-hidden">
      <div className="absolute right-32px top-32px">
        <ChangeLocales />
      </div>
      <div className="relative">
        <div className="w-137px h-80px bg-262626 absolute left-1/2 transform -translate-x-1/2 bottom-full mb-40px text-white flex-center">
          Logo
        </div>
        {children}
      </div>
      <div className="transform text-F4E0E3 text-14px fixed bottom-27px left-1/2 -translate-x-1/2">
        {t('login.copyright')}
      </div>
    </div>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
