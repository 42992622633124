import { CODE_LABEL_COLOR, RELATIONSHIP_CODE } from 'app-constants';
import { THEME } from 'app-constants/theme';
import classNames from 'classnames';
import Tag from 'components/Tag/Tag';
import { getFullNameVN } from 'helpers';
import useActiveMenu from 'hooks/useActiveMenu';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import './ConfigLayout.scss';

function MenuLeft({
  children,
  isShowTitle,
  menus,
  theme,
  classNameMenuLeft,
  classNameContent,
  className,
  isShowMenuLeft = true,
  isShowDetailProject,
}) {
  const history = useHistory();
  const params = useParams();
  const menuActive = useActiveMenu();
  const currentProperty = useSelector((state) => state.property.currentProperty);
  const paramArray = Object.keys(params).map((key) => params[key]);
  const [...p] = paramArray;
  const { t } = useTranslation();

  const onChangeItem = (link) => {
    if (typeof link === 'function' && paramArray?.length) {
      const url = link(...p);
      history.push(url);
    } else {
      history.push(link);
    }
  };

  const owner_name = useMemo(() => {
    if (currentProperty && currentProperty?.residenceList && currentProperty?.residenceList?.length > 0) {
      const findOwner = currentProperty?.residenceList?.filter((item) => item.relationship === RELATIONSHIP_CODE.OWNER);
      if (findOwner?.length) {
        return findOwner.map((item) => getFullNameVN(item)).join(', '); //;
      }
    }
    return '';
  }, [currentProperty]);

  return (
    <div className={classNames('flex h-menuLeft', className)}>
      {isShowMenuLeft && (
        <div className={classNames('w-236px bg-F5F5F5 flex-shrink-0', classNameMenuLeft)}>
          {isShowTitle && (
            <div className="px-16px pt-24px pb-12px text-black opacity-85 text-20px font-medium">
              {t('configuration')}
            </div>
          )}
          {isShowDetailProject && (
            <div className="bg-white pt-24px px-16px pb-16px border-b">
              <p className="text-20px font-medium leading-24px">{currentProperty?.code}</p>
              <div className="flex flex-wrap gap-4px">
                {currentProperty?.labelList &&
                  currentProperty?.labelList.map((label) => (
                    <Tag key={label.id} color={CODE_LABEL_COLOR[label.color]} text={label.name} />
                  ))}
              </div>
              <p className="text-12px mt-8px">
                <span className="capitalize">{t('householder')}</span>: <span>{owner_name}</span>
              </p>
            </div>
          )}

          {menus.map((item, index) => {
            if (item.link === null) {
              return (
                item?.isShow && (
                  <div className="px-16px pt-16px pb-12px" key={index}>
                    <span className="uppercase text-12px text-black opacity-85 font-medium">{t(item.title)}</span>
                  </div>
                )
              );
            } else {
              return (
                item?.isShow && (
                  <div
                    key={item.id}
                    onClick={() => onChangeItem(item.link)}
                    className={classNames(
                      'px-16px pt-12px pb-12px flex items-center gap-12px cursor-pointer item-menu-left',
                      {
                        'active font-medium': menuActive === item.id,
                        cyan: theme === THEME.cyan,
                      },
                    )}
                  >
                    <div className="w-24px h-24px overflow-hidden">{item.icon}</div>
                    <span className="text-black opacity-85">{t(item.title)}</span>
                  </div>
                )
              );
            }
          })}
        </div>
      )}

      <div className={classNames('flex-1 h-full', classNameContent)}>{children}</div>
    </div>
  );
}

MenuLeft.propTypes = {
  children: PropTypes.any,
  isShowTitle: PropTypes.bool,
  isShowMenuLeft: PropTypes.bool,
  menus: PropTypes.any,
  theme: PropTypes.string,
  classNameContent: PropTypes.string,
  classNameMenuLeft: PropTypes.string,
  className: PropTypes.string,
  isShowDetailProject: PropTypes.bool,
};

export default MenuLeft;
