import { AppRoutes } from 'app-constants/AppRoutes';
import { BASE_URL } from 'app-constants/Constants';
import axios from 'axios';
import { notifyError } from 'components/Notification/Notification';
import { startLoading, stopLoading } from 'redux/common/actions';
import { store } from 'redux/store';

let activeRequest = 0;
let countShowExpiredLogin = 0;

const axiosConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers':
      'Origin, Accept, X-Requested-With, Content-Type, Authorization, Access-Control-Allow-Headers',
    'Access-Control-Allow-Method': '*',
    'Access-Control-Allow-Origin': '*',
  },
  timeout: 2 * 60 * 1000,
};

const apiClient = axios.create({
  ...axiosConfig,
  baseURL: BASE_URL,
});

const hideLoading = (axiosObj) => {
  if (!axiosObj.config.notLoading) {
    activeRequest--;
    setTimeout(() => {
      activeRequest === 0 && store.dispatch(stopLoading());
    }, 300);
  }
};

export const setupInterceptors = (history) => {
  apiClient.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (!config.notLoading) {
        activeRequest++;
        store.dispatch(startLoading());
      }

      config.headers['Accept-Language'] = localStorage.getItem('lang');
      if (token) {
        config.headers['Authorization'] = localStorage.getItem('token');
      }
      return config;
    },
    (error) => {
      hideLoading(error);
      return Promise.reject(error);
    },
  );

  apiClient.interceptors.response.use(
    (response) => {
      hideLoading(response);
      return response?.data;
    },
    (error) => {
      hideLoading(error);
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        history.push(AppRoutes.login);
        if (countShowExpiredLogin === 0) {
          notifyError(error.response?.data?.message);
          countShowExpiredLogin++;
        }
      }
      if (error?.response?.data) {
        if (error.response?.data?.code === '506') {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          history.push(`${AppRoutes.login}`);
        }
        return Promise.reject(error.response?.data);
      }
      return Promise.reject(error.message);
    },
  );
};

export default apiClient;
