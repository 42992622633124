import * as Yup from 'yup';
import i18n from '../i18n';

export const uppercaseUnicode = /[A-ZAÁÀÃẠÂẤẦẪẬĂẮẰẴẶEÉÈẼẸÊẾỀỄỆIÍÌĨỊOÓÒÕỌÔỐỒỖỘƠỚỜỠỢUÚÙŨỤƯỨỪỮỰYÝỲỸỴĐ]/;
export const lowercaseUnicode = /[a-zàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ]/;
export const regexPhone = /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/;
const regexLetter = /^[aA-zZ\s\d\p{L}]+$/u; //Ký tự cho phép: chữ, số, space - unicode
const regexOnlyText = /^[aA-zZ\s\p{L}]+$/u; //Ký tự cho phép: chữ, space - unicode
const regexAddress = /^[aA-zZ\s\d\-\\.\\,/\p{L}]+$/u; //Ký tự cho phép: ",.-/" và chữ, số, space - unicode
const regexLetterNotSpace = /^[aA-zZ\d]+$/; //Ký tự cho phép: chữ vs số
const regexNumber = /^[\d]+$/;
const regexEmail = /^(\s*)[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}(\s*)$/g;
const regexEmailNoUnderscore = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
// const regexIdentityCard = /^[0-9\A-Z\d]+$/;
const regexUserCode = /^(?!NV0000$)(?!$)NV\d{4}/;
const regexIdNumber = /[^0]+/;
const regexProjectManager = /^[aA-zZ,\s\p{L}]+$/u; //Ký tự cho phép: "," và chữ, space - unicode
const regexNameDepartment = /^[aA-zZ,\s\-\p{L}]+$/u; //Ký tự cho phép: ",-" và chữ, space - unicode
const regexPropertyCode = /^[aA-zZ\d]{10}$/;

const checkSpecialCharacter = (value) => /[`\\^\\[\]_]/.test(value); // ký tự: `^_[]\

const isEmptyValue = (value) => {
  return !![null, undefined, 'null', 'undefined'].includes(value);
};

export const yupString = ({
  isRequired,
  isEmail,
  isEmailNoUnderscore,
  isPhone,
  isAddress,
  isText,
  isIdNumber,
  isPropertyCode,
  fieldCode,
  isNotSymbol,
  isNumber,
  isNotSpace,
  isUserCode,
  isPassword,
  min,
  max,
  isProjectManager,
  isNameDepartment,
}) => {
  let yupObj = Yup.string();
  if (isRequired) {
    yupObj = yupObj
      .required(
        i18n.t('fieldNotEmpty', {
          field: i18n.t(fieldCode),
        }),
      )
      .matches(
        /^(?!\s+$).*/,
        i18n.t('fieldNotEmpty', {
          field: i18n.t(fieldCode),
        }),
      );
  } else {
    yupObj = yupObj.nullable().notRequired();
  }
  if (isEmail) {
    yupObj = yupObj.matches(
      regexEmail,
      i18n.t('fieldIncorrect', {
        field: i18n.t(fieldCode),
      }),
    );
  }
  if (isEmailNoUnderscore) {
    yupObj = yupObj.matches(
      regexEmailNoUnderscore,
      i18n.t('fieldIncorrect', {
        field: i18n.t(fieldCode),
      }),
    );
  }
  if (isIdNumber) {
    yupObj = yupObj.matches(
      regexIdNumber,
      i18n.t('fieldIncorrect', {
        field: i18n.t(fieldCode),
      }),
    );
  }
  if (isPropertyCode) {
    yupObj = yupObj
      .matches(
        regexIdNumber,
        i18n.t('fieldIncorrect', {
          field: i18n.t(fieldCode),
        }),
      )
      .test(
        'test-regexPropertyCode',
        i18n.t('fieldIncorrect', {
          field: i18n.t(fieldCode),
        }),
        (value) => regexPropertyCode.test(value),
      );
  }

  if (isPhone) {
    yupObj = yupObj.matches(
      regexPhone,
      i18n.t('fieldIncorrect', {
        field: i18n.t(fieldCode),
      }),
    );
  }
  if (isUserCode) {
    yupObj = yupObj.matches(
      regexUserCode,
      i18n.t('fieldIncorrect', {
        field: i18n.t(fieldCode),
      }),
    );
  }
  if (isAddress) {
    yupObj = yupObj
      .matches(
        regexAddress,
        i18n.t('fieldIncorrect', {
          field: i18n.t(fieldCode),
        }),
      )
      .test(
        'test-symbol',
        i18n.t('fieldIncorrect', {
          field: i18n.t(fieldCode),
        }),
        (value) => !checkSpecialCharacter(value),
      );
  }
  if (isNotSymbol) {
    yupObj = yupObj
      .matches(
        regexLetter,
        i18n.t('fieldIncorrect', {
          field: i18n.t(fieldCode),
        }),
      )
      .test(
        'test-symbol',
        i18n.t('fieldIncorrect', {
          field: i18n.t(fieldCode),
        }),
        (value) => !checkSpecialCharacter(value),
      );
  }
  if (isNotSpace) {
    yupObj = yupObj.matches(
      regexLetterNotSpace,
      i18n.t('fieldIncorrect', {
        field: i18n.t(fieldCode),
      }),
    );
  }
  if (isText) {
    yupObj = yupObj.matches(
      regexOnlyText,
      i18n.t('fieldIncorrect', {
        field: i18n.t(fieldCode),
      }),
    );
  }
  if (isProjectManager) {
    yupObj = yupObj.matches(
      regexProjectManager,
      i18n.t('fieldIncorrect', {
        field: i18n.t(fieldCode),
      }),
    );
  }
  if (isNameDepartment) {
    yupObj = yupObj
      .test(
        'test-symbol',
        i18n.t('fieldIncorrect', {
          field: i18n.t(fieldCode),
        }),
        (value) => !checkSpecialCharacter(value),
      )
      .matches(
        regexNameDepartment,
        i18n.t('fieldIncorrect', {
          field: i18n.t(fieldCode),
        }),
      );
  }
  if (isNumber) {
    yupObj = yupObj.matches(
      regexNumber,
      i18n.t('fieldIncorrect', {
        field: i18n.t(fieldCode),
      }),
    );
  }
  if (isPassword) {
    yupObj = yupObj
      .test(
        'test-minCharacter',
        i18n.t('fieldIncorrect', {
          field: i18n.t(fieldCode),
        }),
        (value) => /^.{10,}$/.test(value),
      )
      .test(
        'test-hasNumber',
        i18n.t('fieldIncorrect', {
          field: i18n.t(fieldCode),
        }),
        (value) => /^(?=.*\d).+$/.test(value),
      )
      .test(
        'test-hasStringUppercase',
        i18n.t('fieldIncorrect', {
          field: i18n.t(fieldCode),
        }),
        (value) => uppercaseUnicode.test(value),
      )
      .test(
        'test-hasStringNormal',
        i18n.t('fieldIncorrect', {
          field: i18n.t(fieldCode),
        }),
        (value) => lowercaseUnicode.test(value),
      )
      .test(
        'test-hasSymbol',
        i18n.t('fieldIncorrect', {
          field: i18n.t(fieldCode),
        }),
        (value) => /[`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/.test(value),
      );
  }
  if (!isEmptyValue(min))
    yupObj = yupObj.min(
      min,
      i18n.t('fieldIncorrect', {
        field: i18n.t(fieldCode),
        length: min,
      }),
    );
  if (!isEmptyValue(max))
    yupObj = yupObj.max(
      max,
      i18n.t('fieldIncorrect', {
        field: i18n.t(fieldCode),
        length: max,
      }),
    );
  yupObj = yupObj.typeError(
    i18n.t('fieldNotEmpty', {
      field: i18n.t(fieldCode),
    }),
  );
  return yupObj;
};

export const yupNumber = ({ isRequired, fieldCode, min, max, isPositive, isInteger }) => {
  let yupObj = Yup.number();
  if (isRequired) {
    yupObj = yupObj.required(
      i18n.t('fieldNotEmpty', {
        field: i18n.t(fieldCode),
      }),
    );
  } else {
    yupObj = yupObj.nullable().notRequired();
  }
  if (!isEmptyValue(min))
    yupObj = yupObj.min(
      min,
      i18n.t('fieldIncorrect', {
        field: i18n.t(fieldCode),
      }),
    );
  if (!isEmptyValue(max))
    yupObj = yupObj.max(
      max,
      i18n.t('fieldIncorrect', {
        field: i18n.t(fieldCode),
      }),
    );
  if (isPositive)
    yupObj = yupObj.positive(
      i18n.t('fieldIncorrect', {
        field: i18n.t(fieldCode),
      }),
    );
  if (isInteger)
    yupObj = yupObj.integer(
      i18n.t('fieldIncorrect', {
        field: i18n.t(fieldCode),
      }),
    );
  return yupObj;
};

export const yupProjectArea = yupNumber({
  fieldCode: 'project_area',
  isRequired: true,
  isInteger: true,
  isPositive: true,
  min: 1,
});

export const yupString255 = ({ fieldCode, isRequired = false }) =>
  yupString({ isRequired, isNotSymbol: true, fieldCode, max: 255 });

export const yupCode = yupString({ isRequired: true, fieldCode: 'code', isNotSymbol: true, min: 6, max: 6 });

export const yupStringRequired = ({ fieldCode, min, max, isNotSymbol, isNotSpace, isNumber, isText, isPassword }) =>
  yupString({ isRequired: true, isNotSymbol, fieldCode, min, max, isNotSpace, isNumber, isText, isPassword });
export const yupStringNullable = ({ fieldCode, min, max, isNotSymbol, isNotSpace, isNumber }) =>
  yupString({ isRequired: false, isNotSymbol, fieldCode, min, max, isNotSpace, isNumber });
export const yupEmailRequired = yupString({ isRequired: true, isEmail: true, fieldCode: 'Email', max: 255 });
export const yupEmailNoUnderscoreRequired = yupString({
  isRequired: true,
  isEmailNoUnderscore: true,
  fieldCode: 'Email',
  max: 255,
});
export const yupEmailNullable = yupString({ isRequired: false, isEmail: true, fieldCode: 'Email', max: 255 });
export const yupPhoneRequired = yupString({
  isRequired: true,
  fieldCode: 'PhoneNumber',
  isPhone: true,
  min: 10,
  max: 11,
});
export const yupPhoneNullable = yupString({
  isRequired: false,
  fieldCode: 'PhoneNumber',
  isPhone: true,
  min: 10,
  max: 11,
});

export const yupFirstName = yupStringRequired({ isText: true, fieldCode: 'firstName', isNotSymbol: true });
export const yupLastName = yupStringRequired({ isText: true, fieldCode: 'lastName', isNotSymbol: true });
export const yupMiddleName = yupString({ isText: true, fieldCode: 'middleName', isNotSymbol: true });
export const yupUsername = yupString({
  isRequired: true,
  isNotSymbol: true,
  fieldCode: 'user_name',
  max: 50,
  isNotSpace: true,
});

export const yupIdNumber = yupString({
  isRequired: true,
  fieldCode: 'IdNumber',
  isNotSymbol: true,
  isIdNumber: true,
  isNotSpace: true,
  min: 8,
  max: 20,
});

export const yupIdNumberNullable = yupString({
  isRequired: false,
  fieldCode: 'IdNumber',
  isNotSymbol: true,
  isIdNumber: true,
  isNotSpace: true,
  min: 8,
  max: 20,
});

export default {};
