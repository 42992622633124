import i18n from '../i18n';

export const TIME_DEBOUNCE = 300;
export const START_DATE_CODE = '01/01/1900';

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_NUMBER = 1;

export const FORMAT_DATE_VN = 'DD/MM/YYYY';
export const FORMAT_DATE_YMD = 'YYYYMMDD';

export const LANG_CODE = {
  vi: 'vi',
  en: 'en',
};

export const LANG = [
  {
    id: 'vi',
    name: i18n.t('vietnamese'),
  },
  {
    id: 'en',
    name: i18n.t('english'),
  },
];

export const CONFIG_OTP_CODE = {
  EMAIL: 1,
  PHONE: 2,
};

export const GENDER_CODE = {
  MALE: 0,
  FEMALE: 1,
  OTHER: 2,
};

export const GENDER = [
  { value: GENDER_CODE.MALE, label: i18n.t('male') },
  { value: GENDER_CODE.FEMALE, label: i18n.t('female') },
  { value: GENDER_CODE.OTHER, label: i18n.t('other') },
];

export const ACTION_CODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  DETAIL: 'DETAIL',
};

export const LABEL_COLOR_CODE = {
  GREEN: 0,
  BROWN: 1,
  BLUE: 2,
  ORANGE: 3,
  RED: 4,
  BLACK: 5,
};
export const LABEL_COLOR = [
  { value: LABEL_COLOR_CODE.GREEN, background: 'bg-389E0D', color: '#389E0D' },
  { value: LABEL_COLOR_CODE.BROWN, background: 'bg-910D11', color: '#910D11' },
  { value: LABEL_COLOR_CODE.BLUE, background: 'bg-2F54EB', color: '#2F54EB' },
  { value: LABEL_COLOR_CODE.ORANGE, background: 'bg-FAAD14', color: '#FAAD14' },
  { value: LABEL_COLOR_CODE.RED, background: 'bg-F5222D', color: '#F5222D' },
  { value: LABEL_COLOR_CODE.BLACK, background: 'bg-262626', color: '#262626' },
];
export const CODE_LABEL_COLOR = {
  0: 'bg-389E0D',
  1: 'bg-910D11',
  2: 'bg-2F54EB',
  3: 'bg-FAAD14',
  4: 'bg-F5222D',
  5: 'bg-262626',
};

export const PROCEDURE_CODE = {
  IN_PROGRESS: '0',
  DELAY_PROGRESS: '1',
  NOT_YET_TAKEN_OVER: '2',
  PREPARING_TAKEN_OVER: '3',
  TAKEN_OVER: '4',
};

export const CODE_PROCEDURE = {
  0: 'in_progress',
  1: 'delay_progress',
  2: 'not_yet_taken_over',
  3: 'preparing_taking_over',
  4: 'taken_over',
};

export const PROCEDURE = [
  {
    key: PROCEDURE_CODE.IN_PROGRESS,
    label: 'in_progress',
  },
  {
    key: PROCEDURE_CODE.DELAY_PROGRESS,
    label: 'delay_progress',
  },
  {
    key: PROCEDURE_CODE.NOT_YET_TAKEN_OVER,
    label: 'not_yet_taken_over',
  },
  {
    key: PROCEDURE_CODE.PREPARING_TAKEN_OVER,
    label: 'preparing_taking_over',
  },
  {
    key: PROCEDURE_CODE.TAKEN_OVER,
    label: 'taken_over',
  },
];

export const PROPERTY_TYPE_CODE = {
  SHOPHOUSE: '5',
  MASION: '6',
  VILLA: '4',
  TOWNHOUSE: '3',
  LUXURY_BUILDING: '2',
  ORDINARY_BUILDING: '1',
  OTHER: '0',
};

export const CODE_PROPERTY_TYPE = {
  0: 'Other',
  1: 'ordinary_apartment_building',
  2: 'luxury_apartment_building',
  3: 'townhouse',
  4: 'villa',
  5: 'shophouse',
  6: 'mansion',
};

export const PROPERTY_TYPE = [
  {
    key: PROPERTY_TYPE_CODE.VILLA,
    label: 'villa',
  },
  {
    key: PROPERTY_TYPE_CODE.SHOPHOUSE,
    label: 'shophouse',
  },
  {
    key: PROPERTY_TYPE_CODE.MASION,
    label: 'mansion',
  },
  {
    key: PROPERTY_TYPE_CODE.TOWNHOUSE,
    label: 'townhouse',
  },
  {
    key: PROPERTY_TYPE_CODE.LUXURY_BUILDING,
    label: 'luxury_apartment_building',
  },
  {
    key: PROPERTY_TYPE_CODE.ORDINARY_BUILDING,
    label: 'ordinary_apartment_building',
  },
  {
    key: PROPERTY_TYPE_CODE.OTHER,
    label: 'Other',
  },
];

export const SERVICE_STATE_CODE = {
  UNIHABITED: 0,
  OCCUPIED: 1,
};

export const CODE_SERVICE_STATE = {
  0: 'uninhabited',
  1: 'occupied',
};

export const SERVICE_STATE = [
  {
    value: SERVICE_STATE_CODE.UNIHABITED,
    label: i18n.t('uninhabited'),
  },
  {
    value: SERVICE_STATE_CODE.OCCUPIED,
    label: i18n.t('occupied'),
  },
];

export const NATIONALITY_CODE = {
  VIET_NAM: 1,
  FOREIGNER: 0,
};

export const NATIONALITY = [
  {
    value: NATIONALITY_CODE.VIET_NAM,
    label: i18n.t('text.vietnam'),
  },
  {
    value: NATIONALITY_CODE.FOREIGNER,
    label: i18n.t('text.foreigner'),
  },
];

export const VIP_BACKLIST_CODE = {
  VIP: 1,
  BACKLIST: 0,
};

export const VIP_BACKLIST = [
  {
    value: VIP_BACKLIST_CODE.VIP,
    label: i18n.t('VIP'),
  },
  {
    value: VIP_BACKLIST_CODE.BACKLIST,
    label: i18n.t('BACKLIST'),
  },
];

export const RELATIONSHIP_CODE = {
  OWNER: 0,
  TENANTS: 1,
  OWNER_FAMILY: 2,
  TENANT_FAMILY: 3,
  OTHER: 4,
};

export const RELATIONSHIP = [
  {
    value: RELATIONSHIP_CODE.OWNER,
    label: i18n.t('householder'),
  },
  {
    value: RELATIONSHIP_CODE.TENANTS,
    label: i18n.t('tenants'),
  },
  {
    value: RELATIONSHIP_CODE.OWNER_FAMILY,
    label: i18n.t('owner_family'),
  },
  {
    value: RELATIONSHIP_CODE.TENANT_FAMILY,
    label: i18n.t('tenant_family'),
  },
  {
    value: RELATIONSHIP_CODE.OTHER,
    label: i18n.t('Other'),
  },
];

export const STATE_CODE = {
  0: 'no',
  1: 'yes',
};

export const CODE_RELATIONSHIP = {
  0: 'householder',
  1: 'tenants',
  2: 'owner_family',
  3: 'tenant_family',
  4: 'Other',
};

export const CODE_NATIONALITY = {
  0: 'text.foreigner',
  1: 'text.vietnam',
};

export const CODE_GENDER = {
  0: 'male',
  1: 'female',
  2: 'Other',
};

export const ROLE_CODE = {
  0: 'member',
  1: 'householder',
};

export const MEMBER_ROLE = {
  MEMBER: 0,
  HOUSEHOLDER: 1,
};

export const STRUCT_TYPE_CODE = {
  0: i18n.t('project.area'),
  1: i18n.t('project.lot'),
  2: i18n.t('project.building'),
  3: i18n.t('project.floor'),
};

export const STRUCT_TYPE = [
  { value: 0, label: i18n.t('project.area') },
  { value: 1, label: i18n.t('project.lot') },
  { value: 2, label: i18n.t('project.building') },
  { value: 3, label: i18n.t('project.floor') },
];

export const PERMISSION_CODE = {
  0: i18n.t('management'),
  1: i18n.t('permission_employee'),
};

export const PERMISSION = [
  { value: 1, label: i18n.t('management') },
  { value: 0, label: i18n.t('permission_employee') },
];

export const NOTIFICATION_TYPE = {
  1: 'password',
  2: 'birthday',
  3: 'propose_member',
  4: 'edit_member',
  5: 'propose_active',
  6: 'cancel_active',
};

export const REQUEST_TYPE_CODE = {
  0: i18n.t('recommend.request.type.add'),
  1: i18n.t('recommend.request.type.edit'),
  2: i18n.t('recommend.request.type.active'),
  3: i18n.t('recommend.request.type.deactive'),
  4: i18n.t('recommend.request.type.other'),
};

export const REQUEST_TYPE = [
  {
    value: 0,
    label: i18n.t('recommend.request.type.add'),
  },
  {
    value: 1,
    label: i18n.t('recommend.request.type.edit'),
  },
  {
    value: 2,
    label: i18n.t('recommend.request.type.active'),
  },
  {
    value: 3,
    label: i18n.t('recommend.request.type.deactive'),
  },
  {
    value: 4,
    label: i18n.t('recommend.request.type.other'),
  },
];

export const PROPOSE_TYPE = {
  0: i18n.t('propose.pending'),
  1: i18n.t('propose.approved'),
  2: i18n.t('propose.rejected'),
};

export const TYPE_SEND_MAIL = {
  SMTP: 1,
  API: 0,
};
