import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { AppRoutes } from 'app-constants/AppRoutes';
import { THEME } from 'app-constants/theme';
import MenuTopProject from 'components/MenuTop/MenuTopProject';
import MenuLeft from 'layouts/ConfigLayout/MenuLeft';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import PROJECT_ACTION from 'redux/project/actions';
import SITE_ACTION from 'redux/site/actions';

const ProfileLayout = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { idProject } = params;

  useEffect(() => {
    if (!idProject) {
      history.push(AppRoutes.page403);
    } else {
      SITE_ACTION.getInfoSite(idProject).then((res) => {
        if (res) {
          dispatch(PROJECT_ACTION.setCurrentProject(res));
        }
      });
    }
  }, [dispatch, history, idProject]);

  const MENU_LEFT_CONFIG_PROFILE = [
    {
      isShow: true,
      id: 'menuAccount',
      title: 'account',
      link: null,
      icon: '',
    },
    {
      isShow: true,
      id: 'menuInfoUser',
      title: 'personal_information',
      link: AppRoutes.configProfile,
      icon: <AccountCircleOutlinedIcon />,
    },
    {
      isShow: true,
      id: 'menuAccounSecure',
      title: 'account_security',
      link: AppRoutes.configProfileSecure,
      icon: <AdminPanelSettingsOutlinedIcon />,
    },
    {
      isShow: true,
      id: 'menuLanguage',
      title: 'language',
      link: AppRoutes.configProfileLang,
      icon: <LanguageOutlinedIcon />,
    },
  ];

  return (
    <div className="config-layout">
      <MenuTopProject />
      <MenuLeft
        menus={MENU_LEFT_CONFIG_PROFILE}
        isShowTitle={true}
        classNameContent="overflow-hidden"
        theme={THEME.cyan}
      >
        {children}
      </MenuLeft>
    </div>
  );
};

ProfileLayout.propTypes = {
  children: PropTypes.node,
};

export default ProfileLayout;
