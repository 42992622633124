import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import { AppRoutes } from 'app-constants/AppRoutes';
import { MENU_IDS } from 'app-constants/menu';
import { THEME } from 'app-constants/theme';
import MenuTopProject from 'components/MenuTop/MenuTopProject';
import MenuLeft from 'layouts/ConfigLayout/MenuLeft';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import PROJECT_ACTION from 'redux/project/actions';
import SITE_ACTION from 'redux/site/actions';

const ProjectLayout2 = ({ children }) => {
  const history = useHistory();
  const params = useParams();
  const { idProject } = params;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!idProject) {
      history.push(AppRoutes.page403);
    } else {
      SITE_ACTION.getInfoSite(idProject).then((res) => {
        if (res) {
          dispatch(PROJECT_ACTION.setCurrentProject(res));
        }
      });
    }
  }, [dispatch, history, idProject]);

  const MENU_LEFT_PROJECT = [
    {
      id: MENU_IDS.menuProjectOverview,
      title: 'project.menu.overview',
      link: AppRoutes.projectOverview(idProject),
      icon: <DashboardOutlinedIcon />,
    },
    {
      id: 'projectProperty',
      title: 'project.menu.PropertyManagement',
      link: AppRoutes.projectProperty(idProject),
      icon: <MapsHomeWorkOutlinedIcon />,
    },
    {
      id: 'projectResident',
      title: 'project.menu.ResidentManagement',
      link: AppRoutes.projectResident(idProject),
      icon: <GroupsOutlinedIcon />,
    },
    {
      id: 'projectDepartment',
      title: 'project.menu.departmentManagement',
      link: AppRoutes.projectDepartment(idProject),
      icon: <RecentActorsOutlinedIcon />,
    },
    {
      id: 'projectEmployee',
      title: 'project.menu.employeeManagement',
      link: AppRoutes.projectEmployee(idProject),
      icon: <BadgeOutlinedIcon />,
    },
    {
      id: MENU_IDS.menuProjectInformation,
      title: 'project.menu.ProjectInformation',
      link: AppRoutes.projectInformation(idProject),
      icon: <InfoOutlinedIcon />,
    },
    {
      id: MENU_IDS.menuProjectStructure,
      title: 'project.menu.ProjectStructure',
      link: AppRoutes.projectStructure(idProject),
      icon: <AccountTreeOutlinedIcon />,
    },
  ];

  return (
    <div className="config-layout">
      <MenuTopProject />

      <MenuLeft
        isShowMenuLeft={false}
        isShowTitle={false}
        menus={MENU_LEFT_PROJECT}
        theme={THEME.cyan}
        classNameMenuLeft="pt-16px"
        classNameContent="bg-F5F5F5 border-l border-E0E5E9 overflow-hidden"
        className="h-menuLeft"
      >
        {children}
      </MenuLeft>
    </div>
  );
};

ProjectLayout2.propTypes = {
  children: PropTypes.node,
};

export default ProjectLayout2;
