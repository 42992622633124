import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Tooltip } from 'antd';
import { AppRoutes } from 'app-constants/AppRoutes';
import { ROLE_VIEW_LIST_PROJECT, ROLE_VIEW_OVERVIEW_PROJECT } from 'app-constants/permission';
import { THEME } from 'app-constants/theme';
import ChangeLocales from 'components/ChangeLocales/ChangeLocales';
import NotificationCount from 'components/NotificationCount/NotificationCount';
import UserMenu from 'components/UserMenu/UserMenu';
import { useClickOutside } from 'hooks/useClickOutside';
import { hasRole } from 'hooks/usePermission';
import usePermissionByType from 'hooks/usePermissionByType';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import PROJECT_ACTION from 'redux/project/actions';
import SiteList from './SiteList';

function MenuTopProject() {
  const history = useHistory();
  const dispatch = useDispatch();
  const siteName = useSelector((state) => state.project.currentProject?.siteName);
  const imgSite = useSelector((state) => state.project.currentProject?.siteLogo);
  const setting = useSelector((state) => state.user.setting);
  const { idProject } = useParams();
  const [openListSite, setOpenListSite] = useState(false);
  const siteRef = useRef();
  const [sites, setSites] = useState([]);

  const { roleBQL, roleQLTT } = usePermissionByType({ siteId: setting?.siteId });

  const onResetProject = () => {
    if (setting?.isCentral === 1) {
      // QLTT
      if (hasRole(roleQLTT?.LIST_PROJECT, ROLE_VIEW_LIST_PROJECT)) {
        dispatch(PROJECT_ACTION.setCurrentProject(null));
        history.push(AppRoutes.project);
      }
    } else if (setting?.isCentral === 0) {
      //BQL
      if (hasRole(roleBQL?.OVERVIEW, ROLE_VIEW_OVERVIEW_PROJECT) && idProject) {
        history.push(AppRoutes.projectOverview(idProject));
      }
    }
  };

  useClickOutside(siteRef, () => {
    setOpenListSite(false);
  });

  useEffect(() => {
    if (hasRole(roleQLTT?.LIST_PROJECT, ROLE_VIEW_LIST_PROJECT)) {
      PROJECT_ACTION.getProjects().then((res) => {
        if (res?.sites?.length) {
          setSites(res?.sites);
        }
      });
    }
  }, [roleQLTT?.LIST_PROJECT]);

  return (
    <div
      className="h-48px bg-03A89C text-white flex justify-between pl-16px pr-28px select-none relative"
      ref={siteRef}
    >
      {/* left */}
      <div className="flex items-center flex-1">
        <HomeOutlinedIcon onClick={onResetProject} className="cursor-pointer" />
        <div className="w-1px h-24px bg-008996 mx-16px" />
        {imgSite && (
          <div className="w-20px h-20px mr-20px">
            <img src={imgSite} alt="" className="w-full h-full object-cover" />
          </div>
        )}

        <p
          className="text-16px text-white cursor-pointer max-w-300px line-clamp-1"
          onClick={() => {
            if (sites?.length) setOpenListSite(!openListSite);
          }}
        >
          <Tooltip title={siteName}>{siteName}</Tooltip>
        </p>
        <span>
          <KeyboardArrowDownIcon />
        </span>
        <ChangeLocales />
      </div>
      {/* right */}
      <div className="flex items-center gap-30px">
        <NotificationCount />
        <UserMenu theme={THEME.cyan} />
      </div>

      {openListSite && <SiteList sites={sites} setOpenListSite={setOpenListSite} />}
    </div>
  );
}

export default MenuTopProject;
