import { notification } from 'antd';
import classNames from 'classnames';
import './Notification.scss';

const notify = (type, title, description) => {
  notification[type]({
    message: title,
    description: description,
    top: 32,
    className: classNames('customNotify', {
      success: type === 'success',
      error: type === 'error',
    }),
    onClick: () => {
      console.log('Notification Clicked!');
    },
  });
};

export const notifySuccess = (title, description) => {
  notify('success', title, description);
};

export const notifyError = (title, description) => {
  notify('error', title, description);
};
