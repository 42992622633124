import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Tag.scss';

function Tag({ text, color, className }) {
  return (
    <div
      className={classNames(
        'rounded-4px h-24px flex-center text-14px leading-16px inline-flex px-8px mt-8px',
        color,
        className,
      )}
    >
      {text}
    </div>
  );
}

Tag.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.any,
};

export default Tag;
