import { initializeApp } from 'firebase/app';
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  updateDoc,
  doc,
  getDoc,
  setDoc,
  where,
  orderBy,
  limit,
  startAfter,
  enableIndexedDbPersistence,
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDDXBAJ7tvAwuavjafdqXJxYvVhf5ftkdg',
  authDomain: 'web-bql.firebaseapp.com',
  projectId: 'web-bql',
  storageBucket: 'web-bql.appspot.com',
  messagingSenderId: '222691127999',
  appId: '1:222691127999:web:ccd1112b42d669c41f8c0a',
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const enableOffline = async () => {
  try {
    await enableIndexedDbPersistence(db);
  } catch (err) {
    console.log(err);
  }
};
enableOffline();

const streamNotifications = async (userId, last_seen, snapshot, error) => {
  try {
    if (userId && last_seen > 0) {
      const itemsColRef = collection(db, 'users', userId, 'notifications');
      const itemsQuery = query(itemsColRef, where('created_at', '>=', last_seen));
      return onSnapshot(itemsQuery, snapshot, error);
    }
  } catch (err) {}
};

const updateLastSeen = async (userId, time) => {
  try {
    const userColRef = doc(db, 'users', userId);
    await updateDoc(userColRef, {
      last_seen: time,
    });
  } catch (error) {}
};

const getLastSeen = async (userId) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data?.last_seen) {
        return data?.last_seen;
      }
      await updateDoc(userDocRef, {
        last_seen: 1,
      });
      return 1;
    }
    await setDoc(userDocRef, {
      last_seen: 1,
    });
    return 1;
  } catch (error) {
    return 1;
  }
};

const getNotifications = async (userId, lastDoc, isLast, isNext, snapshot, error) => {
  try {
    let notificationsQuery;
    const notificationsRef = collection(db, 'users', userId, 'notifications');

    if (!isNext && !isLast && !lastDoc) {
      notificationsQuery = query(notificationsRef, orderBy('created_at', 'desc'), limit(10));
    } else {
      if (lastDoc && isNext) {
        notificationsQuery = query(notificationsRef, orderBy('created_at', 'desc'), limit(10), startAfter(lastDoc));
      }
    }
    return onSnapshot(notificationsQuery, snapshot, error);
  } catch (error) {}
};

export { db, streamNotifications, updateLastSeen, getLastSeen, getNotifications };
