import { actionTypes } from '../actionTypes';

const initialState = {
  currentProperty: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CURRENT_PROPERTY:
      return { ...state, currentProperty: action.payload };

    default:
      return state;
  }
}
