import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AppRoutes } from 'app-constants/AppRoutes';
import { useHistory } from 'react-router-dom';

function SiteList({ sites, setOpenListSite }) {
  const { t } = useTranslation();
  const history = useHistory();
  const changeSite = (idProject) => {
    history.push(AppRoutes.projectOverview(idProject));
    setOpenListSite(false);
  };

  return (
    <div className="absolute w-full z-20 right-0 top-topMenuSite shadow-menuUser bg-white px-24px pt-24px pb-32px">
      <p className="text-16px font-medium text-black">{t('project.charge')}:</p>
      <div className="flex gap-32px mt-14px">
        {sites &&
          sites?.length &&
          sites.map((item, index) => (
            <div className="w-240px" key={index}>
              <div className="border border-F0F0F0 w-full h-160px cursor-pointer" onClick={() => changeSite(item?.id)}>
                <img src={item?.siteLogo} alt="imgSite" className="w-full h-full object-cover" />
              </div>
              <p
                className="text-16px leading-20px text-black mt-16px cursor-pointer"
                onClick={() => changeSite(item?.id)}
              >
                {item?.siteName}
              </p>
              <p className="text-12px leading-18px text-black mt-4px">{item?.siteAddress}</p>
            </div>
          ))}
      </div>
    </div>
  );
}

SiteList.propTypes = {
  sites: PropTypes.any,
};

export default SiteList;
