import { useSelector } from 'react-redux';
import usePermission from './usePermission';

const arrEmpty = [];

function usePermissionByType({ siteId }) {
  const setting = useSelector((state) => state.user.setting);
  const { permission } = useSelector((state) => state.user) ?? {};

  const { allRoles: roleBQL } = usePermission({
    listPermissionId: siteId ? permission?.BQL?.[siteId] : arrEmpty,
  });

  const { allRoles: roleQLTT } = usePermission({
    listPermissionId: permission?.QLTT,
  });

  return { roleBQL, roleQLTT, isCentral: setting?.isCentral === 1 };
}

export default usePermissionByType;
