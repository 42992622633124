import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json';
import translationVI from './locales/vi.json';

const resources = {
  en: {
    translation: translationEN,
  },
  vi: {
    translation: translationVI,
  },
};

if (!localStorage.getItem('lang')) {
  localStorage.setItem('lang', 'vi');
}

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lang') ?? 'vi',
  fallbackLng: 'vi',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export const appI18n = i18n;

export default i18n;
