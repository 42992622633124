import PropTypes from 'prop-types';
import { Button as ButtonAntd } from 'antd';
import classNames from 'classnames';
import './Button.scss';

function Button({ className, name, type, ...props }) {
  return (
    <ButtonAntd className={classNames('custom-button', className)} type={type} {...props}>
      {name}
    </ButtonAntd>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  name: PropTypes.node,
  onClick: PropTypes.func,
};

export default Button;
