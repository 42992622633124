export const PAGES = {
  DEPARTMENT_CENTRAL: 'DEPARTMENT_CENTRAL',
  EMPLOYEE_CENTRAL: 'EMPLOYEE_CENTRAL',
  LIST_PROJECT: 'LIST_PROJECT',
  OVERVIEW: 'OVERVIEW',
  INFO_PROJECT: 'INFO_PROJECT',
  PROJECT_MANAGER_STRUCTURE: 'PROJECT_MANAGER_STRUCTURE',
  CONFIG_RESIDENT_VIP: 'CONFIG_RESIDENT_VIP',
  CONFIG_LABEL_PROPERTY: 'CONFIG_LABEL_PROPERTY',
  CONFIG_MAIL_SERVER: 'CONFIG_MAIL_SERVER',
  CONFIG_SMS_GATEWAY: 'CONFIG_SMS_GATEWAY',
  PROPERTY: 'PROPERTY',
  RESIDENT: 'RESIDENT',
  DEPARTMENT: 'DEPARTMENT',
  EMPLOYEE: 'EMPLOYEE',
};

// DEPARTMENT CENTRAL
export const ROLE_VIEW_EMPLOYEE_DEPARTMENT = 'ROLE_VIEW_EMPLOYEE_DEPARTMENT'; // nhan su trực thuộc
export const ROLE_ADD_EMPLOYEE_DEPARTMENT = 'ROLE_ADD_EMPLOYEE_DEPARTMENT'; // thêm nstt
export const ROLE_DELETE_EMPLOYEE_DEPARTMENT = 'ROLE_DELETE_EMPLOYEE_DEPARTMENT'; // xóa nstt

//EMPLOYEE CENTRAL
export const ROLE_ACTIVE_EMPLOYEE = 'ROLE_ACTIVE_EMPLOYEE'; // active/deactive
export const ROLE_RESET_PASS_EMPLOYEE = 'ROLE_RESET_PASS_EMPLOYEE';

//PROJECT MANAGEER
export const ROLE_VIEW_LIST_PROJECT = 'ROLE_VIEW_LIST_PROJECT';
export const ROLE_VIEW_OVERVIEW_PROJECT = 'ROLE_VIEW_OVERVIEW_PROJECT';
//CONFIG
//MAIL
export const ROLE_TEST_SEND_MAIL = 'ROLE_TEST_SEND_MAIL';
//SEND SMS
export const ROLE_TEST_SEND_SMS = 'ROLE_TEST_SEND_SMS';
export const ROLE_ADD_TEMPLATE_SMS = 'ROLE_ADD_TEMPLATE_SMS';

//RECOMMEND RESIDENT
export const ROLE_VIEW_LIST_RECOMMEND = 'ROLE_VIEW_LIST_RECOMMEND';
export const ROLE_APPROVE_REJECT_RECOMMEND = 'ROLE_APPROVE_REJECT_RECOMMEND';

//PROPERTY
export const ROLE_ASIGN_LABEL_PROPERTY = 'ROLE_ASIGN_LABEL_PROPERTY'; // gán nhãn
export const ROLE_VIEW_HISTORY_PROPERTY = 'ROLE_VIEW_HISTORY_PROPERTY'; // lịch sử
export const ROLE_VIEW_MEMBERS_PROPERTY = 'ROLE_VIEW_MEMBERS_PROPERTY'; // ds thành viên
export const ROLE_VIEW_DETAIL_MEMBER_PROPERTY = 'ROLE_VIEW_DETAIL_MEMBER_PROPERTY'; // CHI TIET thành viên
export const ROLE_ADD_MEMBER_PROPERTY = 'ROLE_ADD_MEMBER_PROPERTY'; // add thành viên
export const ROLE_EDIT_MEMBER_PROPERTY = 'ROLE_EDIT_MEMBER_PROPERTY'; // edit thành viên
export const ROLE_ACTIVE_MEMBER_PROPERTY = 'ROLE_ACTIVE_MEMBER_PROPERTY'; // ACTIVE thành viên
export const ROLE_DEACTIVE_MEMBER_PROPERTY = 'ROLE_DEACTIVE_MEMBER_PROPERTY'; // DEACTIVE thành viên

// OTHER
export const ROLE_VIEW_LIST = 'ROLE_VIEW_LIST';
export const ROLE_VIEW_DETAIL = 'ROLE_VIEW_DETAIL';
export const ROLE_ADD = 'ROLE_ADD';
export const ROLE_EDIT = 'ROLE_EDIT';
export const ROLE_DELETE = 'ROLE_DELETE';
export const ROLE_IMPORT = 'ROLE_IMPORT';
export const ROLE_EXPORT = 'ROLE_EXPORT';

// 1- Admin, 2- Quản lý tập trung, 3- Township, 4- Trưởng ban quản lý, 5- Giám sát dịch vụ,
// 6- Kế toán, 7- Kỹ thuật, 8- Chăm sóc khách hàng

// QUYỀN ADMIN
export const ROLE_ADMIN = {
  // quản lý bộ phận tập trung
  DEPARTMENT_CENTRAL: [
    ROLE_VIEW_LIST,
    ROLE_ADD,
    ROLE_EDIT,
    ROLE_DELETE,
    ROLE_VIEW_DETAIL,
    ROLE_VIEW_EMPLOYEE_DEPARTMENT,
    ROLE_ADD_EMPLOYEE_DEPARTMENT,
    ROLE_DELETE_EMPLOYEE_DEPARTMENT,
  ],
  // quản lý nhân sự tập trung
  EMPLOYEE_CENTRAL: [
    ROLE_VIEW_LIST,
    ROLE_ADD,
    ROLE_EDIT,
    ROLE_DELETE,
    ROLE_VIEW_DETAIL,
    ROLE_ACTIVE_EMPLOYEE,
    ROLE_RESET_PASS_EMPLOYEE,
    ROLE_IMPORT,
    ROLE_EXPORT,
  ],
  // danh sách dự án
  LIST_PROJECT: [ROLE_VIEW_LIST_PROJECT],
  // tổng quan
  OVERVIEW: [ROLE_VIEW_OVERVIEW_PROJECT],
  // thông tin dự án
  INFO_PROJECT: [ROLE_VIEW_LIST, ROLE_EDIT],
  // cấu trúc dự án
  PROJECT_MANAGER_STRUCTURE: [ROLE_VIEW_LIST, ROLE_ADD, ROLE_EDIT, ROLE_DELETE, ROLE_IMPORT],
  // cấu hình vip
  CONFIG_RESIDENT_VIP: [ROLE_VIEW_LIST, ROLE_ADD, ROLE_EDIT],
  // cấu hình bất động sản
  CONFIG_LABEL_PROPERTY: [ROLE_VIEW_LIST, ROLE_ADD, ROLE_EDIT, ROLE_DELETE],
  // cấu hình mail
  CONFIG_MAIL_SERVER: [ROLE_VIEW_LIST, ROLE_ADD, ROLE_EDIT, ROLE_TEST_SEND_MAIL],
  // cấu hình sms
  CONFIG_SMS_GATEWAY: [ROLE_VIEW_LIST, ROLE_ADD, ROLE_EDIT, ROLE_DELETE, ROLE_TEST_SEND_SMS, ROLE_ADD_TEMPLATE_SMS],
  // BDS
  PROPERTY: [
    ROLE_VIEW_LIST,
    ROLE_VIEW_DETAIL,
    ROLE_ADD,
    ROLE_EDIT,
    ROLE_EXPORT,
    ROLE_IMPORT,
    ROLE_DELETE,
    ROLE_ASIGN_LABEL_PROPERTY,
    ROLE_VIEW_HISTORY_PROPERTY,
    ROLE_VIEW_MEMBERS_PROPERTY,
    ROLE_VIEW_DETAIL_MEMBER_PROPERTY,
    ROLE_ADD_MEMBER_PROPERTY,
    ROLE_EDIT_MEMBER_PROPERTY,
    ROLE_ACTIVE_MEMBER_PROPERTY,
    ROLE_DEACTIVE_MEMBER_PROPERTY,
  ],
  // cư dân
  RESIDENT: [
    ROLE_VIEW_LIST,
    ROLE_VIEW_DETAIL,
    ROLE_ADD,
    ROLE_EDIT,
    ROLE_EXPORT,
    ROLE_IMPORT,
    ROLE_DELETE,
    ROLE_VIEW_LIST_RECOMMEND,
    ROLE_APPROVE_REJECT_RECOMMEND,
  ],
  // bộ phận
  DEPARTMENT: [
    ROLE_VIEW_LIST,
    ROLE_ADD,
    ROLE_EDIT,
    ROLE_DELETE,
    ROLE_VIEW_DETAIL,
    ROLE_VIEW_EMPLOYEE_DEPARTMENT,
    ROLE_ADD_EMPLOYEE_DEPARTMENT,
    ROLE_DELETE_EMPLOYEE_DEPARTMENT,
  ],
  EMPLOYEE: [
    ROLE_VIEW_LIST,
    ROLE_ADD,
    ROLE_EDIT,
    ROLE_DELETE,
    ROLE_VIEW_DETAIL,
    ROLE_ACTIVE_EMPLOYEE,
    ROLE_RESET_PASS_EMPLOYEE,
    ROLE_IMPORT,
    ROLE_EXPORT,
  ],
};

// QUYỀN QLTT
export const ROLE_CENTRAL = {
  DEPARTMENT_CENTRAL: [ROLE_VIEW_LIST, ROLE_VIEW_DETAIL, ROLE_VIEW_EMPLOYEE_DEPARTMENT],
  EMPLOYEE_CENTRAL: [ROLE_VIEW_LIST],
  LIST_PROJECT: [ROLE_VIEW_LIST_PROJECT],
  OVERVIEW: [ROLE_VIEW_OVERVIEW_PROJECT],
  INFO_PROJECT: [ROLE_VIEW_LIST],
  PROJECT_MANAGER_STRUCTURE: [],
  CONFIG_RESIDENT_VIP: [],
  CONFIG_LABEL_PROPERTY: [],
  CONFIG_MAIL_SERVER: [],
  CONFIG_SMS_GATEWAY: [],
  PROPERTY: [],
  RESIDENT: [],
  DEPARTMENT: [],
  EMPLOYEE: [],
};

//QUYỀN Township
export const ROLE_TOWNSHIP = {
  DEPARTMENT_CENTRAL: [
    ROLE_VIEW_LIST,
    ROLE_ADD,
    ROLE_EDIT,
    ROLE_DELETE,
    ROLE_VIEW_DETAIL,
    ROLE_VIEW_EMPLOYEE_DEPARTMENT,
    ROLE_ADD_EMPLOYEE_DEPARTMENT,
    ROLE_DELETE_EMPLOYEE_DEPARTMENT,
  ],
  EMPLOYEE_CENTRAL: [
    ROLE_VIEW_LIST,
    ROLE_ADD,
    ROLE_EDIT,
    ROLE_DELETE,
    ROLE_VIEW_DETAIL,
    ROLE_ACTIVE_EMPLOYEE,
    ROLE_RESET_PASS_EMPLOYEE,
    ROLE_IMPORT,
    ROLE_EXPORT,
  ],
  LIST_PROJECT: [ROLE_VIEW_LIST_PROJECT],
  OVERVIEW: [ROLE_VIEW_OVERVIEW_PROJECT],
  INFO_PROJECT: [ROLE_VIEW_LIST, ROLE_EDIT],
  PROJECT_MANAGER_STRUCTURE: [ROLE_VIEW_LIST, ROLE_ADD, ROLE_EDIT, ROLE_IMPORT],
  CONFIG_RESIDENT_VIP: [ROLE_VIEW_LIST, ROLE_ADD, ROLE_EDIT],
  CONFIG_LABEL_PROPERTY: [ROLE_VIEW_LIST, ROLE_ADD, ROLE_EDIT, ROLE_DELETE],
  CONFIG_MAIL_SERVER: [],
  CONFIG_SMS_GATEWAY: [],
  PROPERTY: [ROLE_VIEW_LIST, ROLE_VIEW_DETAIL, ROLE_ADD, ROLE_EDIT, ROLE_EXPORT, ROLE_IMPORT],
  RESIDENT: [ROLE_VIEW_LIST],
  DEPARTMENT: [
    ROLE_VIEW_LIST,
    ROLE_ADD,
    ROLE_EDIT,
    ROLE_DELETE,
    ROLE_VIEW_DETAIL,
    ROLE_VIEW_EMPLOYEE_DEPARTMENT,
    ROLE_ADD_EMPLOYEE_DEPARTMENT,
    ROLE_DELETE_EMPLOYEE_DEPARTMENT,
  ],
  EMPLOYEE: [
    ROLE_VIEW_LIST,
    ROLE_ADD,
    ROLE_EDIT,
    ROLE_DELETE,
    ROLE_VIEW_DETAIL,
    ROLE_ACTIVE_EMPLOYEE,
    ROLE_RESET_PASS_EMPLOYEE,
    ROLE_IMPORT,
    ROLE_EXPORT,
  ],
};

// QUYỀN trưởng ban quản lý
export const ROLE_HEAD_MANAGEMENT = {
  DEPARTMENT_CENTRAL: [],
  EMPLOYEE_CENTRAL: [],
  LIST_PROJECT: [],
  OVERVIEW: [ROLE_VIEW_OVERVIEW_PROJECT],
  INFO_PROJECT: [ROLE_VIEW_LIST],
  PROJECT_MANAGER_STRUCTURE: [],
  CONFIG_RESIDENT_VIP: [],
  CONFIG_LABEL_PROPERTY: [],
  CONFIG_MAIL_SERVER: [],
  CONFIG_SMS_GATEWAY: [],
  PROPERTY: [
    ROLE_VIEW_LIST,
    ROLE_VIEW_DETAIL,
    ROLE_EXPORT,
    ROLE_ASIGN_LABEL_PROPERTY,
    ROLE_VIEW_HISTORY_PROPERTY,
    ROLE_VIEW_MEMBERS_PROPERTY,
    ROLE_VIEW_DETAIL_MEMBER_PROPERTY,
    ROLE_ADD_MEMBER_PROPERTY,
    ROLE_EDIT_MEMBER_PROPERTY,
    ROLE_ACTIVE_MEMBER_PROPERTY,
    ROLE_DEACTIVE_MEMBER_PROPERTY,
  ],
  RESIDENT: [
    ROLE_VIEW_LIST,
    ROLE_VIEW_DETAIL,
    ROLE_ADD,
    ROLE_EDIT,
    ROLE_IMPORT,
    ROLE_DELETE,
    ROLE_VIEW_LIST_RECOMMEND,
    ROLE_APPROVE_REJECT_RECOMMEND,
  ],
  DEPARTMENT: [
    ROLE_VIEW_LIST,
    ROLE_ADD,
    ROLE_EDIT,
    ROLE_DELETE,
    ROLE_VIEW_DETAIL,
    ROLE_VIEW_EMPLOYEE_DEPARTMENT,
    ROLE_ADD_EMPLOYEE_DEPARTMENT,
    ROLE_DELETE_EMPLOYEE_DEPARTMENT,
  ],
  EMPLOYEE: [
    ROLE_VIEW_LIST,
    ROLE_ADD,
    ROLE_EDIT,
    ROLE_DELETE,
    ROLE_VIEW_DETAIL,
    ROLE_ACTIVE_EMPLOYEE,
    ROLE_RESET_PASS_EMPLOYEE,
    ROLE_IMPORT,
    ROLE_EXPORT,
  ],
};

// QUYỀN giám sát dịch vụ
export const ROLE_MONITOR_SERVICE = {
  DEPARTMENT_CENTRAL: [],
  EMPLOYEE_CENTRAL: [],
  LIST_PROJECT: [],
  OVERVIEW: [ROLE_VIEW_OVERVIEW_PROJECT],
  INFO_PROJECT: [ROLE_VIEW_LIST],
  PROJECT_MANAGER_STRUCTURE: [],
  CONFIG_RESIDENT_VIP: [],
  CONFIG_LABEL_PROPERTY: [],
  CONFIG_MAIL_SERVER: [],
  CONFIG_SMS_GATEWAY: [],
  PROPERTY: [ROLE_VIEW_LIST, ROLE_VIEW_DETAIL, ROLE_EXPORT, ROLE_ASIGN_LABEL_PROPERTY, ROLE_VIEW_HISTORY_PROPERTY],
  RESIDENT: [],
  DEPARTMENT: [],
  EMPLOYEE: [],
};

// QUYỀN KE TOAN
export const ROLE_ACCOUNTANT = {
  DEPARTMENT_CENTRAL: [],
  EMPLOYEE_CENTRAL: [],
  LIST_PROJECT: [],
  OVERVIEW: [ROLE_VIEW_OVERVIEW_PROJECT],
  INFO_PROJECT: [ROLE_VIEW_LIST],
  PROJECT_MANAGER_STRUCTURE: [],
  CONFIG_RESIDENT_VIP: [],
  CONFIG_LABEL_PROPERTY: [],
  CONFIG_MAIL_SERVER: [],
  CONFIG_SMS_GATEWAY: [],
  PROPERTY: [ROLE_VIEW_LIST, ROLE_VIEW_DETAIL, ROLE_EXPORT, ROLE_ASIGN_LABEL_PROPERTY, ROLE_VIEW_HISTORY_PROPERTY],
  RESIDENT: [],
  DEPARTMENT: [],
  EMPLOYEE: [],
};

// QUYỀN KY THUAT
export const ROLE_TECHNICAL = {
  DEPARTMENT_CENTRAL: [],
  EMPLOYEE_CENTRAL: [],
  LIST_PROJECT: [],
  OVERVIEW: [ROLE_VIEW_OVERVIEW_PROJECT],
  INFO_PROJECT: [ROLE_VIEW_LIST],
  PROJECT_MANAGER_STRUCTURE: [],
  CONFIG_RESIDENT_VIP: [],
  CONFIG_LABEL_PROPERTY: [],
  CONFIG_MAIL_SERVER: [],
  CONFIG_SMS_GATEWAY: [],
  PROPERTY: [],
  RESIDENT: [],
  DEPARTMENT: [],
  EMPLOYEE: [],
};

// QUYỀN  CHAM SOC KHACH HANG
export const ROLE_CUSTOMER_CARE = {
  DEPARTMENT_CENTRAL: [],
  EMPLOYEE_CENTRAL: [],
  LIST_PROJECT: [],
  OVERVIEW: [ROLE_VIEW_OVERVIEW_PROJECT],
  INFO_PROJECT: [ROLE_VIEW_LIST],
  PROJECT_MANAGER_STRUCTURE: [],
  CONFIG_RESIDENT_VIP: [],
  CONFIG_LABEL_PROPERTY: [],
  CONFIG_MAIL_SERVER: [],
  CONFIG_SMS_GATEWAY: [],
  PROPERTY: [
    ROLE_VIEW_LIST,
    ROLE_VIEW_DETAIL,
    ROLE_EXPORT,
    ROLE_ASIGN_LABEL_PROPERTY,
    ROLE_VIEW_HISTORY_PROPERTY,
    ROLE_VIEW_MEMBERS_PROPERTY,
    ROLE_VIEW_DETAIL_MEMBER_PROPERTY,
    ROLE_ADD_MEMBER_PROPERTY,
    ROLE_EDIT_MEMBER_PROPERTY,
    ROLE_ACTIVE_MEMBER_PROPERTY,
    ROLE_DEACTIVE_MEMBER_PROPERTY,
  ],
  RESIDENT: [
    ROLE_VIEW_LIST,
    ROLE_VIEW_DETAIL,
    ROLE_ADD,
    ROLE_EDIT,
    ROLE_IMPORT,
    ROLE_DELETE,
    ROLE_VIEW_LIST_RECOMMEND,
    ROLE_APPROVE_REJECT_RECOMMEND,
  ],
  DEPARTMENT: [],
  EMPLOYEE: [],
};

// const ROLE_EXAMPLE = {
//   DEPARTMENT_CENTRAL: [],
//   EMPLOYEE_CENTRAL: [],
//   LIST_PROJECT: [],
//   OVERVIEW: [],
//   INFO_PROJECT: [],
//   PROJECT_MANAGER_STRUCTURE: [],
//   CONFIG_RESIDENT_VIP: [],
//   CONFIG_LABEL_PROPERTY: [],
//   CONFIG_MAIL_SERVER: [],
//   CONFIG_SMS_GATEWAY: [],
//   PROPERTY: [],
//   RESIDENT: [],
//   DEPARTMENT: [],
//   EMPLOYEE: [],
// };

export const LIST_ROLE = {
  1: ROLE_ADMIN,
  2: ROLE_CENTRAL,
  3: ROLE_TOWNSHIP,
  4: ROLE_HEAD_MANAGEMENT,
  5: ROLE_MONITOR_SERVICE,
  6: ROLE_ACCOUNTANT,
  7: ROLE_TECHNICAL,
  8: ROLE_CUSTOMER_CARE,
};
