import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HomeWorkOutlined from '@mui/icons-material/HomeWorkOutlined';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import { AppRoutes } from 'app-constants/AppRoutes';
import { MENU_IDS } from 'app-constants/menu';
import { THEME } from 'app-constants/theme';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import MenuTopProject from 'components/MenuTop/MenuTopProject';
import MenuLeft from 'layouts/ConfigLayout/MenuLeft';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import PROJECT_ACTION from 'redux/project/actions';
import SITE_ACTION from 'redux/site/actions';
import usePermissionByType from 'hooks/usePermissionByType';
import { hasRole } from 'hooks/usePermission';
import { ROLE_VIEW_LIST, ROLE_VIEW_OVERVIEW_PROJECT } from 'app-constants/permission';

const ProjectLayout = ({ children }) => {
  const breadCrumb = useSelector((state) => state.project.breadCrumb);
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { idProject } = params;

  const { roleBQL, roleQLTT } = usePermissionByType({ siteId: idProject });

  useEffect(() => {
    if (!idProject) {
      history.push(AppRoutes.page403);
    } else {
      SITE_ACTION.getInfoSite(idProject).then((res) => {
        if (res) {
          dispatch(PROJECT_ACTION.setCurrentProject(res));
        }
      });
    }
  }, [dispatch, history, idProject]);

  const MENU_LEFT_PROJECT = [
    {
      isShow:
        hasRole(roleQLTT?.OVERVIEW, ROLE_VIEW_OVERVIEW_PROJECT) ||
        hasRole(roleBQL?.OVERVIEW, ROLE_VIEW_OVERVIEW_PROJECT),
      id: MENU_IDS.menuProjectOverview,
      title: 'project.menu.overview',
      link: AppRoutes.projectOverview(idProject),
      icon: <DashboardOutlinedIcon />,
    },
    {
      isShow: hasRole(roleQLTT?.PROPERTY, ROLE_VIEW_LIST) || hasRole(roleBQL?.PROPERTY, ROLE_VIEW_LIST),
      id: 'projectProperty',
      title: 'project.menu.PropertyManagement',
      link: AppRoutes.projectProperty(idProject),
      icon: <HomeWorkOutlined />,
    },
    {
      isShow: hasRole(roleQLTT?.RESIDENT, ROLE_VIEW_LIST) || hasRole(roleBQL?.RESIDENT, ROLE_VIEW_LIST),
      id: 'projectResident',
      title: 'project.menu.ResidentManagement',
      link: AppRoutes.projectResident(idProject),
      icon: <GroupsOutlinedIcon />,
    },
    {
      isShow: hasRole(roleQLTT?.DEPARTMENT, ROLE_VIEW_LIST) || hasRole(roleBQL?.DEPARTMENT, ROLE_VIEW_LIST),
      id: 'projectDepartment',
      title: 'project.menu.departmentManagement',
      link: AppRoutes.projectDepartment(idProject),
      icon: <RecentActorsOutlinedIcon />,
    },
    {
      isShow: hasRole(roleQLTT?.EMPLOYEE, ROLE_VIEW_LIST) || hasRole(roleBQL?.EMPLOYEE, ROLE_VIEW_LIST),
      id: 'projectEmployee',
      title: 'project.menu.employeeManagement',
      link: AppRoutes.projectEmployee(idProject),
      icon: <BadgeOutlinedIcon />,
    },
    {
      isShow: hasRole(roleQLTT?.INFO_PROJECT, ROLE_VIEW_LIST) || hasRole(roleBQL?.INFO_PROJECT, ROLE_VIEW_LIST),
      id: MENU_IDS.menuProjectInformation,
      title: 'project.menu.ProjectInformation',
      link: AppRoutes.projectInformation(idProject),
      icon: <InfoOutlinedIcon />,
    },
    {
      isShow:
        hasRole(roleQLTT?.PROJECT_MANAGER_STRUCTURE, ROLE_VIEW_LIST) ||
        hasRole(roleBQL?.PROJECT_MANAGER_STRUCTURE, ROLE_VIEW_LIST),
      id: MENU_IDS.menuProjectStructure,
      title: 'project.menu.ProjectStructure',
      link: AppRoutes.projectStructure(idProject),
      icon: <AccountTreeOutlinedIcon />,
    },
  ];

  return (
    <div className="config-layout">
      <MenuTopProject />
      <div className="px-20px h-40px flex items-center shadow-headerConfig border-b z-10">
        <Breadcrumb breadcrumb={breadCrumb} />
      </div>
      <MenuLeft
        isShowTitle={false}
        menus={MENU_LEFT_PROJECT}
        theme={THEME.cyan}
        classNameMenuLeft="pt-16px"
        classNameContent="bg-F5F5F5 border-l border-E0E5E9 overflow-hidden"
        className="h-menuLeftProject"
      >
        {children}
      </MenuLeft>
    </div>
  );
};

ProjectLayout.propTypes = {
  children: PropTypes.node,
};

export default ProjectLayout;
