import { ConfigProvider } from 'antd';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setThemeSystem } from 'redux/setting/actions';

function useThemeSystem() {
  const themeColor = useSelector((state) => state.setting.theme);
  const dispatch = useDispatch();

  const onThemeChange = useCallback(
    (nextColor) => {
      const mergedNextColor = { ...themeColor, ...nextColor };
      dispatch(setThemeSystem(mergedNextColor));
      ConfigProvider.config({
        theme: mergedNextColor,
      });
    },
    [dispatch, themeColor],
  );

  return {
    themeColor,
    onThemeChange,
  };
}

export default useThemeSystem;
