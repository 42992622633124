import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import { ConfigProvider } from 'antd';
import './i18n';
import './styles/index.scss';
import i18n from './i18n';
import english from 'antd/es/locale/en_US';
import vietnamese from 'antd/es/locale/vi_VN';

// import ErrorBoundary from './components/ErrorBoundary';

import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';

const locales = {
  en: english,
  vi: vietnamese,
};

ReactDOM.render(
  // <ErrorBoundary>
  <ConfigProvider locale={i18n.language === 'vi' ? locales['vi'] : locales['en']}>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </ConfigProvider>,
  // </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
