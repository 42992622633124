import { AppRoutes } from 'app-constants/AppRoutes';
import { getToken } from 'helpers/storage';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AUTH_ACTION from 'redux/user/actions';

function NavigateByPermission() {
  const history = useHistory();
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.user.setting);

  useEffect(() => {
    if (!setting && getToken()) {
      dispatch(AUTH_ACTION.getPermission()).then((res) => {
        if (Array.isArray(res) && res.length === 0) {
          history.push(AppRoutes.page403);
        } else {
        }
      });
    }
  }, [dispatch, history, setting]);

  return null;
}

export default NavigateByPermission;
