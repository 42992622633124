import { Suspense, useEffect, useState } from 'react';

import ProgressLoading from 'components/ProgressLoading/ProgressLoading';
import RouteWrapper from './RouteWrapper';

import './styles/App.scss';

import 'font-awesome/css/font-awesome.min.css';
import { getToken } from 'helpers/storage';
import { useDispatch, useSelector } from 'react-redux';
import AUTH_ACTION from 'redux/user/actions';

function App() {
  const { loading } = useSelector((state) => state.common);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const setting = useSelector((state) => state.user.setting);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!setting && getToken()) {
      dispatch(AUTH_ACTION.getAccountSetting());
    }
  }, [dispatch, setting]);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (!navigator.onLine) {
        window.location.reload();
      }
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvement
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  return (
    <Suspense fallback={<ProgressLoading />}>
      {loading && <ProgressLoading />}
      <RouteWrapper />
    </Suspense>
  );
}

export default App;
