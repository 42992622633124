import { actionTypes } from '../actionTypes';

const initialState = {
  fileUpload: null,
};

export default function uploadReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_FILE_UPLOAD:
      return { ...state, fileUpload: action.payload };
    default:
      return state;
  }
}
