import { AppRoutes } from 'app-constants/AppRoutes';
import { THEME } from 'app-constants/theme';
import classNames from 'classnames';
import { getUserName } from 'helpers/storage';
import Utils from 'helpers/Utils';
import { useClickOutside } from 'hooks/useClickOutside';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AUTH_ACTION from 'redux/user/actions';

function UserMenu({ theme = THEME.darkRed }) {
  const [openMenu, setOpenMenu] = useState(false);
  const menuRef = useRef();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const infoAccountPage = useSelector((state) => state.user.infoAccountPage) || {};
  const setting = useSelector((state) => state.user.setting);
  const { idProject } = useParams();
  useEffect(() => {
    dispatch(AUTH_ACTION.getUserDepartmentSite(getUserName()));
  }, [dispatch]);
  const menus = [
    {
      title: t('personal_account'),
      link: '',
      onClick: () => {
        if (setting?.isCentral === 1) {
          // QLTT
          history.push(AppRoutes.config_account);
        } else if (setting?.isCentral === 0 && idProject) {
          //BQL
          history.push(AppRoutes.configProfile(idProject));
        }
      },
    },
    {
      title: t('log_out'),
      link: '',
      onClick: () => AUTH_ACTION.onLogout(history),
    },
  ];

  useClickOutside(menuRef, () => {
    setOpenMenu(false);
  });

  const handleClickItem = (onClickItem) => {
    setOpenMenu(false);
    onClickItem && onClickItem();
  };

  return (
    <div>
      <div ref={menuRef} className="flex items-center gap-8px cursor-pointer relative leading-48px">
        <div className="flex items-center gap-8px select-none" onClick={() => setOpenMenu(!openMenu)}>
          <div className="w-24px h-24px bg-237804 rounded-full overflow-hidden">
            <img src={Utils.getAvatar(infoAccountPage.avatar)} alt="" className="object-cover w-full h-full" />
          </div>
          <p className="text-white">{getUserName()}</p>
        </div>

        {openMenu && (
          <div className="absolute z-20 top-topMenuUser -right-12px shadow-menuUser bg-white rounded-8px py-8px w-219px leading-normal">
            {menus.map((item, i) => {
              return (
                <div
                  key={i}
                  onClick={() => handleClickItem(item.onClick)}
                  className={classNames(
                    'px-16px py-12px text-14px text-black opacity-85 hover:text-white transition-all',
                    {
                      'hover:bg-811209': theme === THEME.darkRed,
                      'hover:bg-03A89C': theme === THEME.cyan,
                    },
                  )}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

UserMenu.propTypes = {
  theme: PropTypes.string,
};

export default UserMenu;
