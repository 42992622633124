import { THEME_DATA } from 'app-constants/theme';
import { actionTypes } from '../actionTypes';

const initialState = {
  menuActive: '',
  theme: THEME_DATA.darkRed,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_MENU:
      return { ...state, menuActive: action.payload };
    case actionTypes.SET_THEME:
      return { ...state, theme: action.payload };

    default:
      return state;
  }
}
