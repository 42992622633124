import PropTypes from 'prop-types';
import MenuTop from '../../components/MenuTop/MenuTop';

const DepartmentLayout = ({ children }) => {
  return (
    <div className="department-layout flex flex-col h-full overflow-hidden">
      <MenuTop />
      <div className="flex-1 h-menuLeft">{children}</div>
    </div>
  );
};

DepartmentLayout.propTypes = {
  children: PropTypes.node,
};

export default DepartmentLayout;
