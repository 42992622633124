import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { AppRoutes } from 'app-constants/AppRoutes';
import { ROLE_VIEW_HISTORY_PROPERTY } from 'app-constants/permission';
import { THEME } from 'app-constants/theme';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import MenuTopProject from 'components/MenuTop/MenuTopProject';
import { hasRole } from 'hooks/usePermission';
import usePermissionByType from 'hooks/usePermissionByType';
import MenuLeft from 'layouts/ConfigLayout/MenuLeft';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import PROJECT_ACTION from 'redux/project/actions';
import PROPERTY_ACTION from 'redux/property/actions';
import SITE_ACTION from 'redux/site/actions';

const PropertyLayout = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const breadCrumb = useSelector((state) => state.project.breadCrumb);
  const params = useParams();
  const { idProject, idProperty } = params;
  const { roleBQL, roleQLTT } = usePermissionByType({ siteId: idProject });

  const MENU_LEFT_PROJECT_PROPERTY = [
    {
      isShow: true,
      id: 'projectPropertyOverview',
      title: 'project.menu.overview',
      link: AppRoutes.projectPropertyOverview,
      icon: <DashboardOutlinedIcon />,
    },
    {
      isShow: true,
      id: 'projectPropertyDebtContro',
      title: 'menu.property.debt_control',
      link: AppRoutes.projectPropertyOverview,
      icon: <QuestionAnswerOutlinedIcon />,
    },
    {
      isShow: true,
      id: 'projectPropertyComplaint',
      title: 'menu.property.complaint_management',
      link: AppRoutes.projectPropertyOverview,
      icon: <PriceChangeOutlinedIcon />,
    },
    {
      isShow:
        hasRole(roleQLTT?.PROPERTY, ROLE_VIEW_HISTORY_PROPERTY) ||
        hasRole(roleBQL?.PROPERTY, ROLE_VIEW_HISTORY_PROPERTY),
      id: 'projectPropertyHistory',
      title: 'menu.property.property_history',
      link: AppRoutes.projectPropertyHistory,
      icon: <MapsHomeWorkOutlinedIcon />,
    },
  ];

  useEffect(() => {
    if (!idProject) {
      history.push(AppRoutes.page403);
    } else {
      SITE_ACTION.getInfoSite(idProject).then((res) => {
        if (res) {
          dispatch(PROJECT_ACTION.setCurrentProject(res));
        }
      });
    }
  }, [dispatch, history, idProject]);

  useEffect(() => {
    if (idProject) {
      PROPERTY_ACTION.getProperty(idProperty);
    }
  }, [idProject, idProperty]);

  return (
    <div className="config-layout">
      <MenuTopProject />
      <div className="px-20px h-40px flex items-center shadow-headerConfig border-b z-10">
        <Breadcrumb breadcrumb={breadCrumb} />
      </div>
      <MenuLeft
        isShowDetailProject={true}
        isShowTitle={false}
        menus={MENU_LEFT_PROJECT_PROPERTY}
        theme={THEME.cyan}
        classNameMenuLeft=""
        classNameContent="bg-F5F5F5 border-l border-E0E5E9 overflow-hidden"
        className="h-menuLeftProject"
      >
        {children}
      </MenuLeft>
    </div>
  );
};

PropertyLayout.propTypes = {
  children: PropTypes.node,
};

export default PropertyLayout;
