import { memo } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { AppRoutes } from './app-constants/AppRoutes';
import AppLayout from './layouts/AppLayout';
import LoginLayout from './layouts/LoginLayout';

import PrivateRoute from 'components/PrivateRoute';

import { THEME } from 'app-constants/theme';
import InjectAxiosInterceptors from 'components/InjectAxiosInterceptors';
import ProfileLayout from 'layouts/ProjectLayout/ProfileLayout';
import ProjectLayout from 'layouts/ProjectLayout/ProjectLayout';
import ProjectLayout2 from 'layouts/ProjectLayout/ProjectLayout2';
import PropertyLayout from 'layouts/ProjectLayout/PropertyLayout';
import Page from 'pages';
import ConfigLayout from './layouts/ConfigLayout/ConfigLayout';
import DepartmentLayout from './layouts/DepartmentLayout/DepartmentLayout';
import NavigateByPermission from 'components/NavigateByPermission/NavigateByPermission';

const exact = true;
const isPrivate = true;
const isAuthRoute = true;

const routes = [
  { path: AppRoutes.login, component: Page.Login, exact, layout: LoginLayout },
  { path: AppRoutes.forgot_password, component: Page.ForgotPassword, exact, layout: LoginLayout },
  { path: AppRoutes.page403, component: Page.Page403, exact },
  { path: AppRoutes.page404, component: Page.Page404, exact },
  { path: AppRoutes.department, component: Page.DepartmentPage, exact, layout: DepartmentLayout, isAuthRoute },
  {
    path: AppRoutes.department_detail,
    component: Page.DepartmentDetailPage,
    exact,
    layout: DepartmentLayout,
    isAuthRoute,
  },
  {
    path: AppRoutes.listEmployee,
    component: Page.ListEmployee,
    exact,
    layout: DepartmentLayout,
    isAuthRoute,
  },
  {
    path: AppRoutes.employee_detail,
    component: Page.EmployeeDetail,
    exact,
    layout: DepartmentLayout,
    isAuthRoute,
  },
  {
    path: AppRoutes.adddEmployee,
    component: Page.AddEmployee,
    exact,
    layout: DepartmentLayout,
    isAuthRoute,
  },
  {
    path: `${AppRoutes.editEmployee}/:id`,
    component: Page.EditEmployee,
    exact,
    layout: DepartmentLayout,
    isAuthRoute,
  },
  {
    path: AppRoutes.uploadEmployee,
    component: Page.UploadEmployee,
    exact,
    isAuthRoute,
  },
  { path: AppRoutes.config_account, component: Page.ConfigAccount, exact, layout: ConfigLayout, isAuthRoute },
  { path: AppRoutes.config_account_edit, component: Page.ConfigAccountEdit, exact, layout: ConfigLayout, isAuthRoute },
  { path: AppRoutes.config_lang, component: Page.ConfigLang, exact, layout: ConfigLayout, isAuthRoute },
  { path: AppRoutes.config_secure, component: Page.ConfigSecure, exact, layout: ConfigLayout, isAuthRoute },
  { path: AppRoutes.config_sms, component: Page.ConfigSMSGateway, exact, layout: ConfigLayout, isAuthRoute },
  {
    path: AppRoutes.config_sms_setting,
    component: Page.ConfigSMSGatewaySetting,
    exact,
    layout: ConfigLayout,
    isAuthRoute,
  },
  {
    path: AppRoutes.config_mail,
    component: Page.ConfigMailServer,
    exact,
    layout: ConfigLayout,
    isAuthRoute,
  },
  {
    path: AppRoutes.config_mail_setting,
    component: Page.ConfigMailServerSetting,
    exact,
    layout: ConfigLayout,
    isAuthRoute,
  },
  {
    path: AppRoutes.config_mail_send,
    component: Page.ConfigMailServerSendMail,
    exact,
    layout: ConfigLayout,
    isAuthRoute,
  },
  {
    path: AppRoutes.config_resident_vip,
    component: Page.ConfigResidentVip,
    exact,
    layout: ConfigLayout,
    isAuthRoute,
  },
  {
    path: AppRoutes.config_resident_vip_edit,
    component: Page.ConfigResidentVipEdit,
    exact,
    layout: ConfigLayout,
    isAuthRoute,
  },
  {
    path: AppRoutes.config_real_estate,
    component: Page.ConfigTagRealEstate,
    exact,
    layout: ConfigLayout,
    isAuthRoute,
  },
  // PROJECT
  {
    path: AppRoutes.project,
    component: Page.ProjectPage,
    exact,
    layout: DepartmentLayout,
    isAuthRoute,
  },
  {
    path: AppRoutes.projectInformation(),
    component: Page.ProjectDetailPage,
    exact,
    layout: ProjectLayout,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: `${AppRoutes.projectInformationEdit()}`,
    component: Page.ProjectEditPage,
    exact,
    layout: ProjectLayout2,
    isAuthRoute,
    theme: THEME.cyan,
  },

  { path: AppRoutes.home, component: Page.HomePage, layout: AppLayout, exact, isPrivate },
  {
    path: AppRoutes.projectOverview(),
    component: Page.ProjectOverview,
    exact,
    layout: ProjectLayout,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.projectResident(),
    component: Page.ProjectResidents,
    exact,
    layout: ProjectLayout,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: `${AppRoutes.projectResidentDetail()}`,
    component: Page.ProjectListResidentDetail,
    exact,
    layout: ProjectLayout,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.projectResidentAdd(),
    component: Page.ProjectListResidentAdd,
    exact,
    layout: ProjectLayout2,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.projectResidentEdit(),
    component: Page.ProjectListResidentEdit,
    exact,
    layout: ProjectLayout2,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.projectUploadResident(),
    component: Page.UploadResident,
    exact,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.projectProperty(),
    component: Page.ProjectPropertyPage,
    exact,
    layout: ProjectLayout,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.projectPropertyEdit(),
    component: Page.ProjectPropertyEdit,
    exact,
    layout: ProjectLayout2,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.projectPropertyAdd(),
    component: Page.ProjectPropertytAdd,
    exact,
    layout: ProjectLayout2,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.projectUploadProperty(),
    component: Page.UploadProperty,
    exact,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.projectPropertyOverview(),
    component: Page.ProjectPropertyOverview,
    layout: PropertyLayout,
    exact,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.projectPropertyHistory(),
    component: Page.ProjectPropertyHistory,
    layout: PropertyLayout,
    exact,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.projectDepartment(),
    component: Page.ProjectDepartmentPage,
    exact,
    layout: ProjectLayout,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.projectStructure(),
    component: Page.ProjectStructurePage,
    exact,
    layout: ProjectLayout,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: `${AppRoutes.projectDepartmentDetail()}`,
    component: Page.ProjectDepartmentDetailPage,
    exact,
    layout: ProjectLayout2,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.projectEmployee(),
    component: Page.ProjectListEmployee,
    exact,
    layout: ProjectLayout,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: `${AppRoutes.projectEmployeeDetail()}`,
    component: Page.ProjectEmployeeDetail,
    exact,
    layout: ProjectLayout2,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.projectAdddEmployee(),
    component: Page.ProjectAddEmployee,
    exact,
    layout: ProjectLayout2,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: `${AppRoutes.projectEditEmployee()}`,
    component: Page.ProjectEditEmployee,
    exact,
    layout: ProjectLayout2,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.projectUploadEmployee(),
    component: Page.ProjectUploadEmployee,
    exact,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.configProfile(),
    component: Page.ConfigProfile,
    exact,
    layout: ProfileLayout,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.configProfileEdit(),
    component: Page.ConfigProfileEdit,
    exact,
    layout: ProfileLayout,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.configProfileLang(),
    component: Page.ConfigProfileLang,
    exact,
    layout: ProfileLayout,
    isAuthRoute,
    theme: THEME.cyan,
  },
  {
    path: AppRoutes.configProfileSecure(),
    component: Page.ConfigProfileSecure,
    exact,
    layout: ProfileLayout,
    isAuthRoute,
    theme: THEME.cyan,
  },
];

const RouteWrapper = memo(() => {
  return (
    <BrowserRouter>
      <InjectAxiosInterceptors />
      <NavigateByPermission />
      <Switch>
        {routes.map((route) => (
          <PrivateRoute key={route.path} {...route} />
        ))}
        <Route exact path="/">
          <Redirect to={AppRoutes.login} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
});

export default RouteWrapper;
