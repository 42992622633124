import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FaceRetouchingNaturalOutlinedIcon from '@mui/icons-material/FaceRetouchingNaturalOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { AppRoutes } from 'app-constants/AppRoutes';
import { ROLE_VIEW_LIST } from 'app-constants/permission';
import { hasRole } from 'hooks/usePermission';
import usePermissionByType from 'hooks/usePermissionByType';
import PropTypes from 'prop-types';
import MenuTop from '../../components/MenuTop/MenuTop';
import MenuLeft from './MenuLeft';

const ConfigLayout = ({ children }) => {
  const { roleQLTT } = usePermissionByType({ siteId: null });

  const MENU_LEFT_CONFIG = [
    {
      isShow: true,
      id: 'menuAccount',
      title: 'account',
      link: null,
      icon: '',
    },
    {
      isShow: true,
      id: 'menuInfoUser',
      title: 'personal_information',
      link: AppRoutes.config_account,
      icon: <AccountCircleOutlinedIcon />,
    },
    {
      isShow: true,
      id: 'menuAccounSecure',
      title: 'account_security',
      link: AppRoutes.config_secure,
      icon: <AdminPanelSettingsOutlinedIcon />,
    },
    {
      isShow: true,
      id: 'menuLanguage',
      title: 'language',
      link: AppRoutes.config_lang,
      icon: <LanguageOutlinedIcon />,
    },
    {
      isShow:
        hasRole(roleQLTT?.CONFIG_SMS_GATEWAY, ROLE_VIEW_LIST) ||
        hasRole(roleQLTT?.CONFIG_MAIL_SERVER, ROLE_VIEW_LIST) ||
        hasRole(roleQLTT?.CONFIG_RESIDENT_VIP, ROLE_VIEW_LIST) ||
        hasRole(roleQLTT?.CONFIG_LABEL_PROPERTY, ROLE_VIEW_LIST),
      id: 'menuOtherConfig',
      title: 'other_configurations',
      link: null,
      icon: '',
    },
    {
      isShow: hasRole(roleQLTT?.CONFIG_SMS_GATEWAY, ROLE_VIEW_LIST),
      id: 'menuSmsGateway',
      title: 'SMS Gateway',
      link: AppRoutes.config_sms,
      icon: <SmsOutlinedIcon />,
    },
    {
      isShow: hasRole(roleQLTT?.CONFIG_MAIL_SERVER, ROLE_VIEW_LIST),
      id: 'menuMail',
      title: 'Mail Server',
      link: AppRoutes.config_mail,
      icon: <EmailOutlinedIcon />,
    },
    {
      isShow: hasRole(roleQLTT?.CONFIG_RESIDENT_VIP, ROLE_VIEW_LIST),
      id: 'menuVip',
      title: 'vip_resident',
      link: AppRoutes.config_resident_vip,
      icon: <FaceRetouchingNaturalOutlinedIcon />,
    },
    {
      isShow: hasRole(roleQLTT?.CONFIG_LABEL_PROPERTY, ROLE_VIEW_LIST),
      id: 'menudRealEstate',
      title: 'property_label',
      link: AppRoutes.config_real_estate,
      icon: <MapsHomeWorkOutlinedIcon />,
    },
  ];

  return (
    <div className="config-layout">
      <MenuTop />
      <MenuLeft menus={MENU_LEFT_CONFIG} isShowTitle={true} classNameContent="overflow-hidden">
        {children}
      </MenuLeft>
    </div>
  );
};

ConfigLayout.propTypes = {
  children: PropTypes.node,
};

export default ConfigLayout;
