import { notifyError } from 'components/Notification/Notification';
import { getMessageErr } from 'helpers/messageError';
import { actionTypes } from 'redux/actionTypes';
import apiClient from 'services/apiClient';

export const setBreadcrumbProject = (breadCrumb) => ({
  type: actionTypes.SET_BREADCRUMB_PROJECT,
  payload: breadCrumb,
});

const handleError = (error) => {
  if (error?.code !== '504') {
    const messageErr = getMessageErr(error);
    notifyError(messageErr?.message);
  }
  return Promise.reject(error);
};

//Async action
const getProjects = async (params) => {
  try {
    const response = await apiClient.get('/site/get-list-site', { params });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const setCurrentProject = (data) => ({
  type: actionTypes.SET_CURRENT_PROJECT,
  payload: data,
});

const getOverviewResidence = async (projectId, params) => {
  try {
    const response = await apiClient.get(`/site/${projectId}/overview/residence`, { params });
    if (response?.result === 1) {
      const residence = response?.data;
      return residence;
    }

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getOverviewTask = async (projectId, params) => {
  try {
    const response = await apiClient.get(`/site/${projectId}/overview/task`, { params });
    if (response?.result === 1) {
      const task = response?.data;
      return task;
    }

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getOverviewNotification = async (projectId, params) => {
  try {
    const response = await apiClient.get(`/site/${projectId}/overview/notifycation`, { params });
    if (response?.result === 1) {
      const notification = response?.data;
      return notification;
    }

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getOverviewProperty = async (projectId, params) => {
  try {
    const response = await apiClient.get(`/site/${projectId}/overview/property`, { params });
    if (response?.result === 1) {
      const property = response?.data;
      return property;
    }

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getOverviewPropertyHandoverStatus = async (projectId, params) => {
  try {
    const response = await apiClient.get(`/site/${projectId}/overview/property/handover-status`, { params });
    if (response?.result === 1) {
      const handoverStatus = response?.data;
      return handoverStatus;
    }

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getOverviewEmployee = async (projectId, params) => {
  try {
    const response = await apiClient.get(`/site/${projectId}/overview/employee`, { params });
    if (response?.result === 1) {
      const employee = response?.data;
      return employee;
    }

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const PROJECT_ACTION = {
  getProjects,
  setCurrentProject,
  getOverviewResidence,
  getOverviewTask,
  getOverviewNotification,
  getOverviewProperty,
  getOverviewEmployee,
  getOverviewPropertyHandoverStatus,
};

export default PROJECT_ACTION;
