import { combineReducers } from 'redux';
import user from './user/reducer';
import setting from './setting/reducer';
import department from './department/reducer';
import fileUpload from './uploadFile/reducer';
import common from './common/reducer';
import project from './project/reducer';
import property from './property/reducer';

const reducer = (history) =>
  combineReducers({
    user,
    setting,
    department,
    fileUpload,
    common,
    project,
    property,
  });
export default reducer;
