import { Redirect, Route, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuthenticated from 'hooks/useAuthenticated';
import { AppRoutes } from 'app-constants/AppRoutes';
import useThemeSystem from 'hooks/useThemeSystem';
import { useEffect } from 'react';
import { THEME, THEME_DATA } from 'app-constants/theme';
import useQueryString from 'hooks/useQuerystring';

const PrivateRoute = ({ layout: Layout, component: Component, isAuthRoute, isPrivate, path, theme, ...props }) => {
  const isAuthenticated = useAuthenticated();
  const { onThemeChange } = useThemeSystem();

  const { pathname } = useLocation();

  useEffect(() => {
    if (theme === THEME.cyan) {
      onThemeChange(THEME_DATA.cyan);
    } else {
      onThemeChange(THEME_DATA.darkRed);
    }
  }, []);

  const { redirect } = useQueryString();

  if (!isAuthenticated && isAuthRoute) {
    return <Redirect key={path} push exact={true} to={AppRoutes.login + '?redirect=' + pathname} />;
  }

  if (isAuthenticated && [AppRoutes.login, AppRoutes.forgot_password].includes(pathname)) {
    return <Redirect push key={path?.toString()} exact={true} to={redirect ?? AppRoutes.config_account} />;
  }

  return (
    <Route
      {...props}
      path={path}
      render={(ownProps) => {
        if (Layout)
          return (
            <Layout>
              <Component {...ownProps} />
            </Layout>
          );
        return <Component {...ownProps} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  layout: PropTypes.any,
  component: PropTypes.any,
  isAuthRoute: PropTypes.bool,
  isPrivate: PropTypes.bool,
  path: PropTypes.string,
};

export default PrivateRoute;
