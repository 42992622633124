import { actionTypes } from '../actionTypes';

export const setMenuActive = (menu) => ({
  type: actionTypes.SET_MENU,
  payload: menu,
});

export const setThemeSystem = (theme) => ({
  type: actionTypes.SET_THEME,
  payload: theme,
});
