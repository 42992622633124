import { GENDER, NATIONALITY, RELATIONSHIP, RELATIONSHIP_CODE, VIP_BACKLIST } from 'app-constants';
import {
  ROLE_ACCOUNTANT,
  ROLE_ADMIN,
  ROLE_CENTRAL,
  ROLE_CUSTOMER_CARE,
  ROLE_HEAD_MANAGEMENT,
  ROLE_MONITOR_SERVICE,
  ROLE_TECHNICAL,
  ROLE_TOWNSHIP,
} from 'app-constants/namePermission';
import { PERMISSION, ROLE_PROPERTY, STATUS_ACCOUNT } from 'app-constants/search';
import _ from 'lodash';
import moment from 'moment/moment';
import Utils from './Utils';

export { default as yupHelper } from './yup';

export const isEmptyValue = (value) => {
  return !![null, undefined, 'null', 'undefined'].includes(value);
};

export const isDecinmail = (value) => {
  if (isEmptyValue(value)) return false;
  return value % 1 !== 0;
};

const getStatusOnOff = (value) => {
  switch (value) {
    case true:
    case '1':
    case 1:
      return '1';
    case false:
    case '0':
    case 0:
      return '0';

    default:
      return '';
  }
};

export const getFullNameVN = (dataObj = {}) => {
  return `${dataObj?.lastName ?? ''} ${dataObj?.middleName ?? ''} ${dataObj?.firstName ?? ''}`;
};

export const getLabelByValueOption = (value, options) => {
  if (_.isNull(options) || _.isNull(value)) return '';
  const res = options.find((item) => item.value === value);
  return res?.label;
};

export const getGender = (gender) => {
  if (_.isNull(gender)) return '';
  return getLabelByValueOption(gender, GENDER);
};

export const getRole = (role) => {
  if (_.isNull(role)) return '';
  role = getStatusOnOff(role);
  return getLabelByValueOption(role, PERMISSION);
};

export const getRoleProperty = (role) => {
  if (_.isNull(role)) return '';
  role = getStatusOnOff(role);
  return getLabelByValueOption(role, ROLE_PROPERTY);
};

export const getStatus = (status) => {
  if (_.isNull(status)) return '';
  status = getStatusOnOff(status);
  return getLabelByValueOption(status, STATUS_ACCOUNT);
};

export const getNationality = (nationality) => {
  if (isEmptyValue(nationality)) return '';
  return getLabelByValueOption(nationality, NATIONALITY);
};

export const getDetailNationality = (resident) => {
  if (isEmptyValue(resident?.nationality)) return '';
  if (resident?.nationality === 0) {
    return resident?.otherNationality;
  } else {
    return getLabelByValueOption(resident?.nationality, NATIONALITY);
  }
};

export const getVipBacklist = (vip) => {
  if (_.isNull(vip)) return '';
  return getLabelByValueOption(vip, VIP_BACKLIST);
};

export const getPhoneNumberDefault = (phone) => {
  if (!phone?.length) return '';
  const phoneDefault = phone.find((item) => item?.isDefault);
  return phoneDefault?.phoneNumber;
};

export const getRelationship = (relationship, relationshipType, isDetail) => {
  const res = RELATIONSHIP.find((item) => item.value === relationship);
  switch (relationship) {
    case RELATIONSHIP_CODE.OWNER:
      return {
        type: 'HOUSE',
        ...res,
      };
    case RELATIONSHIP_CODE.TENANTS:
    case RELATIONSHIP_CODE.OWNER_FAMILY:
    case RELATIONSHIP_CODE.TENANT_FAMILY:
      return {
        type: 'USER',
        ...res,
      };
    case RELATIONSHIP_CODE.OTHER:
      if (isDetail) {
        return {
          type: 'USER',
          value: RELATIONSHIP_CODE.OTHER,
          label: relationshipType,
        };
      }
      return {
        type: 'USER',
        ...res,
      };
    default:
      return '';
  }
};

export const getListPhone = (phones = []) => {
  if (!phones?.length) return '';
  return phones.map((item) => item.phoneNumber).join(', ');
};

// SORT
export const sortAlphabet = (a, b, field) => {
  try {
    if (a?.[field]?.toLowerCase() < b?.[field]?.toLowerCase()) {
      return -1;
    }
    if (a?.[field]?.toLowerCase() > b?.[field]?.toLowerCase()) {
      return 1;
    }
    return 0;
  } catch (error) {
    return 0;
  }
};

export const sortNumber = (a, b, field) => {
  return a?.[field] - b?.[field];
};

export const sortTime = (a, b, field) => {
  return moment(a?.[field]).diff(moment(b?.[field]));
};

// Convert input data
export const convertNumber = (value, max) => {
  if (_.isNull(value)) return '';
  const valueNumber = value.split('').reduce((acc, curr) => {
    if (curr.match('[0-9]')) acc += curr;
    return acc;
  }, '');
  if (max && valueNumber >= max) return max;
  return valueNumber;
};

export const converEmail = (value) => {
  if (_.isNull(value)) return '';
  const valueNumber = value.split('').reduce((acc, curr) => {
    if (curr.match('[0-9aA-zZ.@]')) acc += curr;
    return acc;
  }, '');
  return valueNumber;
};

export const convertNotSpecial = (value) => {
  if (_.isNull(value)) return '';
  const valueNumber = value.split('').reduce((acc, curr) => {
    if (!curr.match('[!@#$%^&*(),.?":{}|<>]')) acc += curr;
    return acc;
  }, '');
  return valueNumber;
};

export const convertPassword = (value) => {
  if (_.isNull(value)) return '';
  value = Utils.toNonAccentVietnamese(value);
  return value.replace(/ /g, '');
};

export const convertIDNumber = (value) => {
  if (_.isNull(value)) return '';
  value = Utils.toNonAccentVietnamese(value);
  return value.split('').reduce((acc, curr) => {
    if (curr.match('[0-9aA-zZ]')) acc += curr;
    return acc;
  }, '');
};

export const convertFieldArea = (character) => {
  if (_.isNull(character)) return '';
  return character.split('').reduce((acc, curr) => {
    if (curr.match('[0-9\\.]')) acc += curr;
    const countDot = acc.split('').reduce((a, b) => {
      if (b === '.') a += 1;
      return a;
    }, 0);
    if (countDot > 1 && acc[acc.length - 1] === '.') {
      acc = acc.slice(0, -1);
    }
    return acc;
  }, '');
};

export const removeLastCharacter = (value) => {
  if (_.isNull(value)) return '';
  return value.slice(0, -1);
};

export const standardizedArea = (value, setFieldValue) => {
  if (_.isNull(value)) return '';
  if (value[value.length - 1] === '.') {
    setFieldValue('area', removeLastCharacter(value));
  }
  return value;
};

export const calculatePercent = (value, total, limit = 1) => {
  if (!value || !total) return 0;
  return ((value * 100) / total).toFixed(limit);
};

export const hasDataPreviousPage = (dataArray = [], pageNum) => {
  if (isEmptyValue(dataArray) || isEmptyValue(pageNum)) return false;
  if (pageNum > 1 && dataArray.length === 1) return true;
  return false;
};

export const trimSpace = (str) => {
  if (!str) return '';
  str.trim(str);
  const str1 = str.replace(/^\s+|\s+$/gm, '');
  return str1.replace(/  +/g, ' ');
};

export const classDisableRole = (value) => {
  return !value ? 'disable-element' : '';
};

export const getModulePermission = (role) => {
  switch (role) {
    case 1: // Admin
      return {
        name: 'Admin',
        roles: ROLE_ADMIN,
      };
    case 2: //  Quản lý tập trung
      return {
        name: 'Quản lý tập trung',
        roles: ROLE_CENTRAL,
      };

    case 3: // Township
      return {
        name: 'Township',
        roles: ROLE_TOWNSHIP,
      };
    case 4: // Trưởng ban quản lý
      return {
        name: 'Trưởng ban quản lý',
        roles: ROLE_HEAD_MANAGEMENT,
      };
    case 5: // Giám sát dịch vụ
      return {
        name: 'Giám sát dịch vụ',
        roles: ROLE_MONITOR_SERVICE,
      };
    case 6: // Kế toán
      return {
        name: 'Kế toán',
        roles: ROLE_ACCOUNTANT,
      };
    case 7: // Kỹ thuật
      return {
        name: 'Kỹ thuật',
        roles: ROLE_TECHNICAL,
      };
    case 8: // Chăm sóc khách hàng
      return {
        name: 'Chăm sóc khách hàng',
        roles: ROLE_CUSTOMER_CARE,
      };
    default:
      return [];
  }
};
