import { actionTypes } from 'redux/actionTypes';

export const startLoading = () => ({
  type: actionTypes.LOADING,
  payload: true,
});

export const stopLoading = () => ({
  type: actionTypes.LOADING,
  payload: false,
});
