import { notifyError, notifySuccess } from 'components/Notification/Notification';
import { getMessageErr } from 'helpers/messageError';
import Utils from 'helpers/Utils';
import apiClient from 'services/apiClient';
import i18n from '../../i18n';

const handleError = (error) => {
  if (error?.code !== '504') {
    const messageErr = getMessageErr(error);
    notifyError(messageErr?.message);
  }
  return Promise.reject(error);
};

const getSiteRole = async () => {
  try {
    const response = await apiClient.get('/site-role/');
    if (response?.result === 1) {
      return response?.data;
    }

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getSite = async () => {
  try {
    const response = await apiClient.get('/site/');
    if (response?.result === 1) {
      return response?.data;
    }

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getInfoSite = async (siteId) => {
  try {
    const response = await apiClient.get('/site/get-info-site', { params: { siteId } });
    if (response?.result === 1) {
      return response?.data;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const getEditInfoSite = async (siteId) => {
  try {
    const response = await apiClient.get('/site/edit-info-site', { params: { siteId } });
    if (response?.result === 1) {
      return response?.data;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const getStructure = async (siteId) => {
  try {
    const response = await apiClient.get('/siteStructure/built-site-structure', { params: { siteId } });
    if (response?.result === 1) {
      return response?.data;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const getNodeStructure = async (id) => {
  try {
    const response = await apiClient.get('/siteStructure/view-site-structure', { params: { id } });
    if (response?.result === 1) {
      return response?.data;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const updateInfoSite = async (dataUpdate) => {
  try {
    const response = await apiClient.post('/site/update-info-site', dataUpdate);
    if (response?.result === 1) {
      return response?.data;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const createSiteStructure = async (dataCreate) => {
  try {
    const response = await apiClient.post('/siteStructure/create-site-structure', dataCreate);
    if (response?.result === 1) {
      notifySuccess(
        Utils.capitalizeFirstLetter(
          i18n.t('addSuccess', { name: Utils.lowerCaseFirstLetter(i18n.t('project.menu.ProjectStructure')) }),
        ),
      );
      return response?.data;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const updateSiteStructure = async (dataCreate) => {
  try {
    const response = await apiClient.put('/siteStructure/update-site-structure', dataCreate);
    if (response?.result === 1) {
      notifySuccess(
        Utils.capitalizeFirstLetter(
          i18n.t('editSuccess', { name: Utils.lowerCaseFirstLetter(i18n.t('project.menu.ProjectStructure')) }),
        ),
      );
      return response?.data;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const deleteNodeStructure = async (id) => {
  try {
    const response = await apiClient.delete(`/siteStructure/delete-site-structure/${id}`);
    if (response?.result === 1) {
      notifySuccess(
        Utils.capitalizeFirstLetter(
          i18n.t('deleteSuccess', { name: Utils.lowerCaseFirstLetter(i18n.t('project.menu.ProjectStructure')) }),
        ),
      );
      return true;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const importStructure = async (file, siteId) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('siteId', siteId);

    const response = await apiClient.post('/siteStructure/upload-site-structure', formData, { notLoading: true });
    if (response?.result === 1) {
      return true;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const uploadImageSite = async (dataUpload) => {
  try {
    const response = await apiClient.post('/site/uploadMultiFile', dataUpload);
    if (response?.result === 1) {
      return response?.data;
    }
    return false;
  } catch (error) {
    handleError(error);
  }
};

const SITE_ACTION = {
  getStructure,
  getSiteRole,
  getSite,
  getInfoSite,
  getEditInfoSite,
  updateInfoSite,
  createSiteStructure,
  updateSiteStructure,
  getNodeStructure,
  deleteNodeStructure,
  importStructure,
  uploadImageSite,
};

export default SITE_ACTION;
