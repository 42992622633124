export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const STATUS_API = {
  success: '200',
  401: '401',
};
export const REGEX_ONLY_SPACE_AFTER_THE_WORD = /^(\w+\s?)*\s*$/;
export const REGEX_TEXT_EXCEPT_SPECIAL_CHARACTERS = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
export const MIN_VALUE_INPUT_NUMBER = 0;
export const MAX_LENGTH_INPUT_NUMBER = 9;
export const MAX_LENGTH_TEN_NUMBER = 10;
export const MAX_LENGTH_TEXT_INPUT = 255;
export const MAX_LENGTH_TEXT_NOTE = 1000;

export const SEARCH_NOT_FOUND = 'table.noData';

export const nccSMSData = [
  {
    id: 1,
    name: 'Nhà cung cấp SMS 1',
  },
  {
    id: 2,
    name: 'Nhà cung cấp SMS 2',
  },
];

export const nccMaillServerData = [
  {
    id: 1,
    name: 'Nhà cung cấp Maill Server 1',
  },
  {
    id: 2,
    name: 'Nhà cung cấp Maill Server 2',
  },
];

export const TemplateSMSData = [
  {
    id: 1,
    name: 'Gửi OTP',
  },
  {
    id: 2,
    name: 'Thông báo phí',
  },
];
