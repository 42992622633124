import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { setupInterceptors } from 'services/apiClient';

function InjectAxiosInterceptors() {
  const history = useHistory();

  useEffect(() => {
    setupInterceptors(history);
  }, [history]);

  return null;
}

export default InjectAxiosInterceptors;
