import { actionTypes } from '../actionTypes';

const initialState = {
  loading: false,
  message: '',
  error: '',
  departments: '',
  total: 0,
};

export default function departmentReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.DEPARTMENT:
      return { ...state, loading: false, departments: action.payload, error: '' };
    default:
      return state;
  }
}
